import React, { FC, useEffect } from 'react'
import { graphql } from 'gatsby'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
// eslint-disable-next-line @typescript-eslint/no-var-requires
require('yup-password')(Yup) // extend yup

import SEO from '../../components/seo'
import Wrapper from '../../components/Wrapper/Wrapper'
import Input from '../../components/Input/Input'
import Checkbox from '../../components/Checkbox/Checkbox'
import Button from '../../components/Button/Button'
import Tooltip from '../../components/Tooltip/Tooltip'
import { Error, FormItem } from '../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import { SubHeading } from '../../components/SubHeading/SubHeading.styled'
import { CustomLink } from '../../components/Link/Link.styled'
import RecaptureTerms from '../../components/RecaptureTerms/RecaptureTerms'
import useAuth from '../../contexts/AuthContext/useAuth'
import RichText from '../../components/RichText'
import useNavigate from '../../hooks/useNavigate'
import { flatten, join } from 'lodash'
import { useLocation } from '@gatsbyjs/reach-router'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useManagePlayers } from './hooks/useManagePlayers'
import { IPageProps } from '../../contexts/PageContext'
import { getSignupSchema } from './validation'
import { fromUrlId } from '../../utils/toUrlId'

const RegisterPage: FC<IPageProps<Queries.RegisterPageQuery>> = ({ data }) => {
  const { sanityRegistrationSettings: settings } = data
  const registrationClosed = !settings

  const contactConsentText = settings?.contactConsentText || ''
  const tAndCsConsentBlocks = settings?.termsAndConditionsConsentText as unknown as any[]

  const formattedConsentSanity = flatten(tAndCsConsentBlocks?.map((el) => el?.children))
  const signupConsentText = join(
    formattedConsentSanity.map((el: { text: string }) => el?.text),
    '',
  )

  const { search } = useLocation()
  const parentUrl = new URLSearchParams(search).get('id') || null
  const parentId = parentUrl && fromUrlId(parentUrl)

  const { register, refetch, isAuthenticated } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onUpdatePlayer, onJoinFamily } = useManagePlayers()
  const signupSchema = getSignupSchema(t)

  useEffect(() => {
    if (sessionStorage.getItem('ageSelected') !== 'over13' && !isAuthenticated) {
      navigate('/signup')
    }
    return () => sessionStorage.removeItem('ageSelected')
  }, [])

  return (
    <GoogleReCaptchaProvider language="en-GB" reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY || ''}>
      <>
        <SEO title={t('signUp.title')} />
        {registrationClosed ? (
          <HeadingWrapper>
            <Heading data-testid="registration-closed-heading">{t('signUp.closed')}</Heading>
          </HeadingWrapper>
        ) : (
          <>
            <HeadingWrapper>
              <Heading secondary small data-testid="page-title">
                {t('signUp.title')}
              </Heading>
              <SubHeading as="h2" tertiarySmall tall data-testid="page-subtitle">
                {t('signUp.subTitle')}
              </SubHeading>
            </HeadingWrapper>
            <Wrapper width="400px">
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                  confirmPassword: '',
                  marketingOptIn: false,
                  termsConditions: false,
                  submit: null,
                  consentText: '',
                }}
                validationSchema={signupSchema}
                validate={(values) => {
                  const errors = {} as { confirmPassword: string }

                  if (values.confirmPassword && values.password && values.confirmPassword !== values.password) {
                    errors.confirmPassword = t('validation.password.notEqual')
                  }
                  return errors
                }}
                onSubmit={async (values, { setErrors }) => {
                  try {
                    const user = await register(values.email, values.password)
                    if (user?.id) {
                      await onUpdatePlayer({
                        id: user?.id,
                        profile: {
                          marketingEmailsText: values.marketingOptIn ? contactConsentText : '',
                          allowMarketingEmails: values.marketingOptIn,
                          consentText: signupConsentText,
                        },
                      })
                    }

                    if (parentId) {
                      await navigate(`/join-family/?id=${parentUrl}&insignup=ture`)
                    } else {
                      await navigate(`/signup/name-details`, { replace: true })
                    }
                  } catch (error) {
                    console.log('e ==', error)
                    const errorMessage = error.message || error?.errors?.[0].message || ''
                    if (errorMessage.includes('An account with the given email already exists.')) {
                      setErrors({ submit: t('errors.api.signUp.userExist') || undefined })
                    } else if (errorMessage.includes('Could not create a player')) {
                      setErrors({ submit: t('errors.signup') || undefined })
                    } else if (errorMessage.includes('Family does not exist')) {
                      setErrors({ submit: t('errors.api.createFamily.noFamily') || undefined })
                    } else {
                      setErrors({ submit: errorMessage || t('errors.generic') })
                    }
                  }
                }}
              >
                {({ values, errors, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting, touched }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <FormItem>
                        <Input
                          type="email"
                          id="email"
                          text={t('common.email') || ''}
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.email ? errors.email : undefined}
                          autoComplete="email"
                          required
                          inputProps={{
                            'data-testid': 'email-input',
                          }}
                        />
                      </FormItem>
                      <FormItem>
                        <Input
                          type="password"
                          id="password"
                          text={t('common.password') || ''}
                          autoComplete="new-password"
                          maxlength={50}
                          value={values.password}
                          tooltip={<Tooltip text={t('validation.password.tooltip') || ''} />}
                          hasPassToggle
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.password ? errors.password : undefined}
                          required
                          inputProps={{
                            'data-testid': 'password-input',
                          }}
                        />
                      </FormItem>
                      <FormItem>
                        <Input
                          type="password"
                          id="confirmPassword"
                          text={t('signUp.inputs.confirmPassword') || ''}
                          autoComplete="new-password"
                          maxlength={50}
                          value={values.confirmPassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.confirmPassword ? errors.confirmPassword : undefined}
                          required
                          inputProps={{
                            'data-testid': 'confirm-password-input',
                          }}
                        />
                      </FormItem>
                      <FormItem>
                        <Checkbox
                          id="marketingOptIn"
                          text={contactConsentText}
                          value={values.marketingOptIn}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          testID="contact-constent-checkbox"
                        />
                      </FormItem>
                      <FormItem tabIndex={0}>
                        <Checkbox
                          id="termsConditions"
                          value={values.termsConditions}
                          onChange={handleChange}
                          text={
                            <RichText
                              blocks={tAndCsConsentBlocks}
                              context={{
                                openLinksInNewTab: true,
                              }}
                            />
                          }
                          error={touched.termsConditions ? errors.termsConditions : undefined}
                          onBlur={handleBlur}
                          required
                          testID="tc-checkbox"
                        />
                      </FormItem>

                      <ErrorMessage
                        name="submit"
                        component={(props) => (
                          <Error data-testid="submit-error-msg" className="checkboxError" {...props} />
                        )}
                      />
                      <Button
                        id="submit-button"
                        type="submit"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                        text={t('signUp.createButton')}
                        secondary
                        testID="create-account-button"
                      />
                    </form>
                  )
                }}
              </Formik>
              <p className="small tall" data-testid="have-an-account-text">
                {t('signUp.haveAccount')}{' '}
                <CustomLink to="/login/" data-testid="login-button">
                  {t('auth.login')}
                </CustomLink>
                .
              </p>
              <RecaptureTerms />
            </Wrapper>
          </>
        )}
      </>
    </GoogleReCaptchaProvider>
  )
}
export default RegisterPage
export const query = graphql`
  query RegisterPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityRegistrationSettings(_id: { regex: "/(drafts.|)registrationSettings/" }, i18n_lang: { eq: $language }) {
      contactConsentText
      termsAndConditionsConsentText: _rawTermsAndConditionsConsentText
    }
  }
`
