import React, { FC, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { IS_IE11 } from '../../styles/device'
import MobileLogo from '../../assets/images/svg/logo-mobile.svg'
import DesktopLogo from '../../assets/images/svg/logo-desktop.svg'
import LottieAnimation from '../LottieAnimation'
import * as withoutSun from '../../assets/animations/without_sun.json'
import * as sun_only from '../../assets/animations/sun_only.json'
import { IEWrapper, LogoContainer, PlayPauseButton } from './Logo.styled'
import ContextLink, { Links } from '../ContextLink'
import usePageContext from '../../hooks/usePageContext'
import useGame from '../../contexts/GameContext/useGame'
import theme from '../../styles/theme'
import lottie from 'lottie-web'
import Play from '../../assets/images/svg/play-svgrepo-com.svg'
import Pause from '../../assets/images/svg/pause-svgrepo-com.svg'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface LogoProps {
  animated?: boolean
  linkTo?: Links
  isNavOpen?: boolean
}

const Logo: FC<LogoProps> = ({ animated = false, linkTo = null, isNavOpen }) => {
  const { width } = useWindowSize()
  const { headerBigLogoShown } = usePageContext()
  const { game } = useGame()
  const centered = !!headerBigLogoShown || false
  const [isPaused, setIsPaused] = useState(false)
  const { t } = useTranslation()

  const animatedLogo = (
    <div>
      <div>
        <LottieAnimation width="500px" animationData={withoutSun} />
        <LottieAnimation width="100px" animationData={sun_only} />
      </div>
    </div>
  )
  const staticLogo = width >= 1024 || centered ? <DesktopLogo /> : <MobileLogo />
  const currentLogo = animated ? animatedLogo : staticLogo

  const handlePause = () => {
    if (!isPaused) {
      lottie.pause()
      setIsPaused(true)
    } else {
      lottie.play()
      setIsPaused(false)
    }
  }

  return (
    <>
      <LogoContainer {...{ animated, centered }} data-testid="header-logo">
        {animated && (width < 768 || IS_IE11) ? (
          <IEWrapper>
            <DesktopLogo className="animatedReplacement" />
          </IEWrapper>
        ) : (
          <>
            {linkTo ? (
              <ContextLink to={linkTo} aria-label={t('aria.label.logo') || ''}>
                {currentLogo}
                {game?.info.name && (
                  <h2
                    style={{
                      color: isNavOpen ? theme.colors.white : theme.colors.body,
                    }}
                  >
                    {game?.info.name}
                  </h2>
                )}
              </ContextLink>
            ) : (
              currentLogo
            )}
          </>
        )}
        {animated && width >= 768 && !IS_IE11 ? (
          <PlayPauseButton onClick={handlePause} aria-label={!isPaused ? t('aria.label.pause') : t('aria.label.play')}>
            {isPaused ? <Play /> : <Pause />}
          </PlayPauseButton>
        ) : null}
      </LogoContainer>
    </>
  )
}

export default Logo
