import React, { FC, PropsWithChildren } from 'react'
import BlockWrapper from '../BlockWrapper/BlockWrapper'
import { format } from 'date-fns'
import ImageWithCaption, { ImageWithCaptionNodeProps } from '../ImageWithCaption/ImageWithCaption'
import Embed from '../Embed/Embed'
import Accordion from '../Accordion/Accordion'
import Resources from '../Resources/Resources'
import CTAGrid from '../CTAGrid'
import OverlayLink from './OverlayLink'
import CtaWrapper from './CtaWrapper'
import VideoCtaBlock from './VideoCtaBlock'
import { ContextProps } from '../CallToAction/CallToActionPanel'
import {
  AccordionProps,
  BlockProps,
  CtaGridProps,
  CtaProps,
  EmbedProps,
  GameVariableProps,
  MarksFunctionsProps,
  NodeStyle,
  ResourcesProps,
  VideoCtaProps,
} from './serializers.intefaces'
import ContextLink from '../ContextLink'

export enum LinkTypes {
  facebook = 'facebookPage',
  twitter = 'twitterPage',
  email = 'emailAddress',
}

export enum GameInfo {
  name = 'gameName',
  email = 'emailAddressText',
  emailLink = 'emailAddressLink',
  start = 'startDate',
  registration = 'registrationDate',
  end = 'endDate',
  miles = 'totalMiles',
  distance = 'locationDistance',
}

export const serializers = (context: ContextProps = {}) => {
  return {
    marks: {
      link: ({ value: { url }, children }: MarksFunctionsProps) => {
        return (
          <a target="_blank" rel="noopener noreferrer" href={url} tabIndex={0}>
            {children}
          </a>
        )
      },

      internalLink: ({ value, children }: MarksFunctionsProps) => {
        let internalLinkHash = ''
        if (value?.internalLinkHash) {
          const intLinkHash = value?.internalLinkHash
          internalLinkHash = intLinkHash[0] === '#' ? intLinkHash : `#${intLinkHash}`
        }
        let internalLink = value?.internalLink

        if (internalLink?.[0] !== '/') {
          internalLink = '/' + internalLink + internalLinkHash
        }

        if (internalLink[internalLink.length - 1] !== '/') {
          internalLink = internalLink + '/' + internalLinkHash
        }

        if (context.openLinksInNewTab) {
          return (
            <a href={internalLink} rel="noopener noreferrer" target="_blank" tabIndex={0}>
              {children}
            </a>
          )
        }

        // @ts-ignore
        return <ContextLink to={internalLink}>{children}</ContextLink>
      },

      overlayLink: ({ value: { link }, children }: MarksFunctionsProps) => {
        if (!link) return <div>children</div>
        return (
          <OverlayLink link={link} context={context}>
            {children}
          </OverlayLink>
        )
      },

      gameLink: ({ value: { link }, children }: MarksFunctionsProps) => {
        const { game } = context
        const CustomLink: FC<PropsWithChildren<{ href: string }>> = ({ href, children }) => (
          <a target="_blank" rel="noopener noreferrer" href={href} tabIndex={0}>
            {children}
          </a>
        )

        switch (link) {
          case LinkTypes.facebook:
            if (!game?.content?.facebookUsername) {
              return children
            }
            return <CustomLink href={`https://facebook.com/${game.content.facebookUsername}`}>{children}</CustomLink>

          case LinkTypes.twitter:
            if (!game?.content?.twitterUsername) {
              return children
            }
            return <CustomLink href={`https://twitter.com/${game.content.twitterUsername}`}>{children}</CustomLink>

          case LinkTypes.email:
            if (!game?.content?.emailAddress) {
              return children
            }
            return <a href={`mailto:${game.content.emailAddress}`}>{children}</a>

          default:
            return children
        }
      },

      emailLink: ({ value: { emailAddress }, children }: MarksFunctionsProps) => {
        return (
          <a href={`mailto:${emailAddress}`} tabIndex={0}>
            {children}
          </a>
        )
      },
    },
    types: {
      block({ children, value }: BlockProps) {
        if (value?.style && /^h\d/.test(value.style)) {
          const HeaderTag = ({ children }): JSX.Element => React.createElement(value.style as NodeStyle, null, children)
          return (
            <BlockWrapper>
              <HeaderTag>{children}</HeaderTag>
            </BlockWrapper>
          )
        }
        return (
          <BlockWrapper>
            <p>{children}</p>
          </BlockWrapper>
        )
      },

      gameVariable({ value }: GameVariableProps) {
        if (!value) return null
        const { data } = value
        const { game } = context
        const formatData = (data: string | Date) => format(new Date(data), 'EEEE do MMMM yyyy')

        switch (data) {
          case GameInfo.name:
            return game?.info.name || null
          case GameInfo.email:
            return game?.content?.emailAddress || null
          case GameInfo.miles:
            return game?.counter?.totalMiles ?? null
          case GameInfo.emailLink:
            return game?.content?.emailAddress ? (
              <a href={`mailto:${game.content.emailAddress}`}>{game.content.emailAddress}</a>
            ) : null
          case GameInfo.start:
            return game?.info.startsAt ? formatData(game?.info.startsAt) : null
          case GameInfo.end:
            return game?.info.endsAt ? formatData(game?.info.endsAt) : null
          case GameInfo.distance:
            return game?.info.settings.locationDistanceMeters || 800
          default:
            return null
        }
      },

      figure(props: ImageWithCaptionNodeProps) {
        return (
          <BlockWrapper>
            <ImageWithCaption props={props} />
          </BlockWrapper>
        )
      },

      embed({ value }: EmbedProps) {
        if (!value?.url) return null
        return (
          <BlockWrapper>
            <Embed url={value.url} caption={value.caption} />
          </BlockWrapper>
        )
      },

      accordion({ value }: AccordionProps) {
        if (!value.items) return null
        return (
          <BlockWrapper>
            <Accordion context={context} items={value.items} />
          </BlockWrapper>
        )
      },

      resources({ value }: ResourcesProps) {
        if (!value) return null
        return (
          <BlockWrapper>
            <Resources title={value.title || ''} items={value.resources || []} />
          </BlockWrapper>
        )
      },

      cta({ value }: CtaProps) {
        return (
          <BlockWrapper>
            <CtaWrapper {...value} {...context} />
          </BlockWrapper>
        )
      },

      ctaGrid({ value }: CtaGridProps) {
        return (
          <CTAGrid>
            {value?.items?.map((v) => (
              <CtaWrapper {...v} {...context} />
            ))}
          </CTAGrid>
        )
      },

      videoCta({ value }: VideoCtaProps) {
        return (
          <BlockWrapper>
            <VideoCtaBlock {...value} />
          </BlockWrapper>
        )
      },
    },
  }
}
