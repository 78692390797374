const theme = {
  // ------ Colors -------
  colors: {
    primary: '#167711',
    secondary: '#0a7c90',
    body: '#044567',

    // Supporting
    midAqua: '#96D6DE',
    midBlue: '#376985',
    midDarkBlue: '#007CBD',
    darkBlue: '#014164',
    brightBlue: '#00b2e8',
    midGrey: '#656565',
    grey: '#d5d5d5',
    darkGrey: '#444444',
    darkGreyAlt: '#4D4D4D',
    midDarkGrey: '#6E6E6E',
    midLightGrey: '#dcdcdc',
    lightGrey: '#eaeaea',
    lighterGrey: '#eeeeee',
    lightYellow: '#FFF3CA',
    orange: '#d04520',
    darkOrange: '#a13619',
    pink: '#D208A0',
    lightPink: '#e2699c',
    green: '#13A69D',
    red: '#FF0000',
    lightRed: '#FFCCCB',
    lightGreen: '#90ee90',
    // Standard colors
    white: '#fff',
    black: '#000',
    fadedBlack: '#383838',
    warning: '#ffc021',
    success: '#47d764',
    error: '#d04520',
    info: '#d5d5d5',
    // Transparent
    transparent: 'rgba(0, 0, 0, 0)',
    whiteTransparent: 'rgba(255, 255, 255, 0.001)', // use in linear gradient instead of transparent to address Safari issue showing greys when going to white.
    white80: 'rgba(255, 255, 255, 0.8)',
    white70: 'rgba(255, 255, 255, 0.7)',
  },
  game_themes: {
    default: {
      bgColor: '#044567',
      headingTextColor: '#044567',
      textColor: '#fff',
      buttonBgColor: '#fff',
      buttonTextColor: '#044567',
    },
    go_active: {
      bgColor: '#258650',
      headingTextColor: '#d04520',
      textColor: '#fff',
      buttonBgColor: '#d04520',
      buttonTextColor: '#fff',
    },
    go_celebrate: {
      bgColor: '#e2699c',
      textColor: '#ffffff',
      buttonBgColor: '#662483',
      buttonTextColor: '#ffffff',
      headingTextColor: '#662483',
    },
    go_explore: {
      bgColor: '#e0ded2',
      textColor: '#044467',
      headingTextColor: '#8cd3f6',
      buttonBgColor: '#8cd3f6',
      buttonTextColor: '#044467',
    },
    go_play: {
      bgColor: '#c7edf0',
      textColor: '#044467',
      buttonBgColor: '#258650',
      buttonTextColor: '#ffffff',
      headingTextColor: '#2ca05e',
    },
    go_travel: {
      bgColor: '#fff689',
      headingTextColor: '#6d6c6c',
      textColor: '#6d6c6c',
      buttonBgColor: '#6d6c6c',
      buttonTextColor: '#fff265',
    },
    go_wild: {
      bgColor: '#2e872a',
      textColor: '#fff',
      headingTextColor: '#8ebf21',
      buttonBgColor: '#E8F3D8',
      buttonTextColor: '#1D551B',
    },
    go_wild_beach: {
      bgColor: '#d04520',
      textColor: '#fff',
      headingTextColor: '#d04520',
      buttonBgColor: '#fff68f',
      buttonTextColor: '#c4411e',
    },
  },
  gradients: {
    grey: 'linear-gradient(90deg, rgba(238,238,238,0.1) 0%, rgba(238,238,238,1) 10%, rgba(238,238,238,1) 90%, rgba(238,238,238,0.1) 100%)',
    yellow:
      'linear-gradient(90deg,rgba(255, 243, 202, 0.1) 0%,rgba(255, 243, 202, 1) 10%,rgba(255, 243, 202, 1) 90%,rgba(255, 243, 202, 0.1) 100%)',
  },

  // ------ Fonts -------
  fonts: {
    primaryFont: `'co-text', Sans-serif`,
    secondaryFont: `'co-headline', Sans-serif`,

    // Weights
    bold: 700,
    normal: 400,

    // Sizes
    baseLineHeight: '25px',
    default: '62.5%',
    xxl: '36px',
    xl: '28px',
    ml: '22px',
    l: '21px',
    m: '18px',
    ms: '17px',
    s: '15px',
    xs: '14px',
    mxs: '13px',
    xxs: '12px',
    xxxs: '10px',
  },
  // ------ Transitions -------
  transitions: {
    quickTransition: '0.15s ease-in-out',
    transition: '0.25s ease-out',
  },

  // ------ Borders -------
  borders: {
    dotted:
      'border-top: 2px dotted #eaeaea; border-top-width:2px; border-right-width:0px; border-bottom-width:0px; border-left-width:0px;',
    dottedBottom:
      'border-bottom: 2px dotted #eaeaea; border-top-width:0px; border-right-width:0px; border-bottom-width:3px; border-left-width:0px;',
  },

  // ------ Z-index elements -------
  zIndex: {
    modalCloseButton: 21,
    header: 20,
    tooltip: 2,
    label: 1,
    navContainer: -1,
    backgroundContainer: -1,
  },

  // ------ Hover states -------
  hover: '&:hover, &:active, &:focus',
  // ------ Dimensions -------
  siteWidthSmall: '600px;',
  siteWidthMedium: '760px',
  siteWidth: '1060px',
  contentWidth: '800px',
  maxHeaderWidth: '1600px',
  logoSize: '153px',
  maxInputWidth: '400px',
  radioCheckboxWidth: '30px',
  narrowModalWidth: '450px',
  narrowModalHeight: '650px',
  largeModalWidth: '848px',
  smallModalWidth: '500px',
  screenReaderOnly: `
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    `,
}

export default theme
