import React, { FC } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import Button from '../../../../components/Button/Button'
import SEO from '../../../../components/seo'
import { Error, FormItem } from '../../../../components/FormElements/FormElements.styled'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import { ErrorMessage, Formik } from 'formik'
import SelectDropdown from '../../../../components/SelectDropdown/SelectDropdown'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import ContextLink from '../../../../components/ContextLink'
import useNavigate from '../../../../hooks/useNavigate'
import LinkButton from '../../../../components/LinkButton/LinkButton'
import { format, isBefore } from 'date-fns'
import { CustomLink } from '../../../../components/Link/Link.styled'
import Tooltip from '../../../../components/Tooltip/Tooltip'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../../../contexts/GameContext/useGame'
import { IPageProps } from '../../../../contexts/PageContext'
import { useManagePlayers } from '../../hooks/useManagePlayers'
import { TeamStatus, useFindAllTeamsQuery } from '../../../../generated/api'

const BeforeTeamsOpen: FC<{ teamsStartsAt: string }> = ({ teamsStartsAt }) => {
  const { t } = useTranslation()
  return (
    <>
      <HeadingWrapper>
        <Heading>{t('joinTeam.beforeTeamOpen.title')}</Heading>
        <SubHeading as="h2" secondaryForm veryTall>
          {t('joinTeam.beforeTeamOpen.subTitle', { date: format(new Date(teamsStartsAt), 'eeee d MMMM h:mm aaa') })}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width={'500px'}>
        <p>
          {t('joinTeam.beforeTeamOpen.subTitle', { date: format(new Date(teamsStartsAt), 'eeee d MMMM h:mm aaa') })}
        </p>
      </Wrapper>
      <Wrapper marginTop={'50px'}>
        <LinkButton to={'/signup/profile-details'} secondary style={{ marginBottom: 10, width: '100%' }}>
          {t('common.continue') || ''}
        </LinkButton>
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <p>{t('common.or')}</p>
        </div>
        <CustomLink style={{ marginBottom: 10, width: '100%', fontSize: '1.8rem' }} to="/signup/create-team">
          {t('joinTeam.createTeam')}
        </CustomLink>
      </Wrapper>
    </>
  )
}

const JoinTeam: FC<IPageProps<Queries.JoinTeamQuery>> = () => {
  const { user } = useAuth()
  const { activeGame: game } = useGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { onJoinTeam } = useManagePlayers()

  const isJoinToTeamDisabled = game?.info.teamsStartsAt && isBefore(new Date(), new Date(game?.info.teamsStartsAt))

  const { data: teamsRes } = useFindAllTeamsQuery({ input: { gameId: game?.id || '' } }, { enabled: Boolean(game?.id) })

  const teams = teamsRes?.findTeams

  const approvedTeams = teams
    ?.map((team) => {
      if (team?.status !== TeamStatus.Approved) return
      return {
        value: team.id,
        display: team.name,
      }
    })
    ?.filter((team) => team)

  const userTeam = (user?.id && teams?.find((team) => team.id === user.info.teamId)) || null

  return (
    <>
      <SEO title={t('joinTeam.title')} />
      {isJoinToTeamDisabled ? (
        <BeforeTeamsOpen teamsStartsAt={game?.info.teamsStartsAt} />
      ) : (
        <>
          <HeadingWrapper>
            <Heading data-testid="page-title">{t('joinTeam.title')}</Heading>
            <SubHeading as="h2" secondaryForm veryTall data-testid="page-subtitle">
              {user?.info.teamId
                ? t('joinTeam.subTitle.haveTeam', { teamName: userTeam?.name || 'Team' })
                : t('joinTeam.subTitle.noTeam')}
            </SubHeading>
          </HeadingWrapper>
          <Wrapper width="400px" textAlign="left">
            {user?.info.teamId ? (
              <ContextLink to="/signup/profile-details">
                <Button type="submit" text={t('common.continue')} secondary />
              </ContextLink>
            ) : (
              <Formik
                enableReinitialize
                initialValues={{
                  teamId: '',
                  submit: null,
                }}
                onSubmit={async (values, { setErrors }) => {
                  try {
                    if (values.teamId && user?.id && values.teamId !== t('joinTeam.input.placeholder')) {
                      await onJoinTeam({
                        teamId: values.teamId,
                        playerId: user?.id,
                      })
                    }
                    await navigate(`/signup/profile-details`, { replace: true })
                  } catch (e) {
                    setErrors({ submit: t('errors.joinTeam') || undefined })
                  }
                }}
              >
                {({ values, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
                  return (
                    <form onSubmit={handleSubmit} noValidate>
                      <fieldset>
                        <legend>
                          {t('joinTeam.input.label')} <Tooltip text={t('joinTeam.input.tooltip') || ''} />
                        </legend>
                        <FormItem>
                          <SelectDropdown
                            id={'teamId'}
                            ariaLabel="Select Team"
                            // @ts-ignore
                            ariaLabelledby="Select Team"
                            options={approvedTeams}
                            placeholder={t('joinTeam.input.placeholder') || ''}
                            value={values.teamId}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            secondary
                            testID="team-select"
                          />
                        </FormItem>
                      </fieldset>

                      <ErrorMessage
                        name="teamId"
                        component={(props) => <Error className="checkboxError" {...props} />}
                      />
                      <ErrorMessage
                        name="submit"
                        component={(props) => <Error className="checkboxError" {...props} />}
                      />
                      <Button
                        type="submit"
                        isSubmitting={isSubmitting}
                        disabled={isSubmitting}
                        text={t('common.continue')}
                        secondary
                      />
                    </form>
                  )
                }}
              </Formik>
            )}
          </Wrapper>
          <Wrapper veryTall>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <p>or</p>
            </div>
            <CustomLink style={{ marginBottom: 10, width: '100%', fontSize: '1.8rem' }} to="/signup/create-team">
              {`Create your own team`}
            </CustomLink>
          </Wrapper>
        </>
      )}
    </>
  )
}

export const query = graphql`
  query JoinTeam($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default JoinTeam
