import React from 'react'
import { Popup as PopupMapbox } from 'react-map-gl'

import CrossIcon from '../../../../../assets/images/svg/cross.svg'
import CardsIcon from '../../../../../assets/images/svg/card.svg'
import AccessibleIcon from '../../../../../assets/images/svg/accessible.svg'
import SignalIcon from '../../../../../assets/images/svg/signal.svg'
import GymIcon from '../../../../../assets/images/svg/gym.svg'
import ArrowIcon from '../../../../../assets/images/svg/right-arrow-angle.svg'
import SchoolIcon from '../../../../../assets/images/svg/school.svg'
import GemIcon from '../../../../../assets/images/svg/gem.svg'
import { InnerWrapper, PopupButton, PopupContent, PopupHeader } from './Popup.styled'
import { getReportHref } from './helpers/getReportHref'
import { getGoogleMapsUrlForCoordinates } from './helpers/getGoogleMapsUrlForCoordinates'
import { getAppleMapsUrlForCoordinates } from './helpers/getAppleMapsUrlForCoordinates'
import { Points } from '../Mapbox.interface'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Maybe } from '../../../../../generated/api'
import { useMapState } from '../Context'
import useSsrLocalStorage from '../../../../../utils/useSsrLocalStorage'
import { isAppleMapsSupported } from '../isAppleMapsSupported'

const PopupIcon = ({ type }: { type?: Maybe<string> }) => {
  switch (type) {
    case 'distributionPoint':
      return <CardsIcon />
    case 'outdoorGym':
      return <GymIcon />
    case 'school':
      return <SchoolIcon />
    case 'gem':
      return <GemIcon />
    default:
      return <SignalIcon />
  }
}

interface Props {
  reportEmail: string
}

const Popup = ({ reportEmail }: Props) => {
  const { t } = useTranslation()
  const {
    popup: { type, feature },
    setPopup,
  } = useMapState()
  const reportHref = getReportHref({ reportEmail, feature, type })
  const [useGoogle] = useSsrLocalStorage('useGoogleMapsForDirections', isAppleMapsSupported() ? 'false' : 'true')

  const isBeatBox = type === Points.beatBox

  const handleExternalMaps = useGoogle
    ? getGoogleMapsUrlForCoordinates(feature?.geometry.coordinates)
    : getAppleMapsUrlForCoordinates(feature?.geometry.coordinates, feature?.properties.name)

  if (!feature) return null

  return (
    <PopupMapbox
      longitude={feature.geometry.coordinates[0]}
      latitude={feature.geometry.coordinates[1]}
      anchor="bottom"
      closeButton={false}
      closeOnClick={false}
      offset={35}
    >
      <InnerWrapper>
        <PopupHeader type={type} accessible={feature.properties.accessible}>
          <PopupIcon type={type} />
          <p style={{ marginBottom: '1px' }}>{feature.properties.name}</p>
          <div id="accessible">
            <AccessibleIcon id={feature.properties.id} alt={t('aria.alt.close')} />
          </div>
          <button type="button" title="close-button" onClick={() => setPopup({ isVisible: false })}>
            <CrossIcon alt={t('aria.alt.close')} />
          </button>
        </PopupHeader>
        <PopupContent>
          <p>{feature.properties.description}</p>
          <PopupButton href={handleExternalMaps} target={useGoogle ? '_blank' : undefined} rel="noopener noreferrer">
            {t('popup.directions')}
            <ArrowIcon />
          </PopupButton>
          {isBeatBox && reportHref && (
            <PopupButton inverted href={reportHref} target="_blank" rel="noopener noreferrer">
              {t('popup.report')}
            </PopupButton>
          )}
        </PopupContent>
      </InnerWrapper>
    </PopupMapbox>
  )
}

export default Popup
