import React, { FC, PropsWithChildren, ReactNode } from 'react'
import { animated, useSpring } from 'react-spring'
import LamppostIcon from '../../assets/images/svg/lamppost-tap-full.svg'
import {
  NavBackground,
  NavButton,
  NavCircle,
  NavCircleTop,
  NavContainer,
  NavIcon,
  NavIconWrapper,
  StyledNav,
} from './Nav.styled'
import useAuth from '../../contexts/AuthContext/useAuth'
import ContextLink, { Links } from '../ContextLink'
import theme from '../../styles/theme'
import usePageContext from '../../hooks/usePageContext'
import useGame from '../../contexts/GameContext/useGame'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import { GameStatus } from '../../generated/api'

interface ClickableLinkProps {
  setIsNavOpen: (shouldNavOpen: boolean) => void
  to: Links
  tabIndex?: number
  testID?: string
}

interface NavProps {
  isNavOpen: boolean
  setIsNavOpen: (shouldNavOpen: boolean) => void
}

export const ClickableLink: FC<PropsWithChildren<ClickableLinkProps>> = ({ children, setIsNavOpen, ...props }) => {
  return (
    <ContextLink {...props} onClick={() => setIsNavOpen(false)}>
      {children}
    </ContextLink>
  )
}

export const Nav: FC<PropsWithChildren<NavProps>> = ({ isNavOpen, setIsNavOpen }) => {
  const { user, isAuthenticated, logout } = useAuth()
  const { changeLanguage } = useI18next()
  const { game: selectedGame, activeGame } = useGame()
  const { t } = useTranslation()
  const backgroundStyles = useSpring({
    backgroundColor: isNavOpen ? theme.colors.fadedBlack : theme.colors.transparent,
    pointerEvents: isNavOpen ? 'all' : 'none',
    opacity: isNavOpen ? '1' : '0',
    config: { tension: 250 },
    display: isNavOpen ? 'block' : 'none',
  })
  const navStyles = useSpring({
    opacity: isNavOpen ? 1 : 0,
    delay: 400,
  })
  const iconStyles = useSpring({
    transform: isNavOpen ? 'translate3d(0,0,0)' : 'translate3d(100%,0,0)',
    delay: 400,
  })

  const { allGames, pageList } = usePageContext()
  const games =
    allGames?.filter(
      (game) =>
        [GameStatus.Anticipation, GameStatus.Live, GameStatus.Sustain].includes(game.info.status) &&
        !game.info.hideOnLanding,
    ) || []

  const linkItems: ReactNode[] = []

  const pageExists = (page: string) => pageList?.some((x) => x.includes(page))

  if (!user && !selectedGame?.id) {
    games.forEach((game) => {
      const { slug, name } = game.info
      linkItems.push(
        <li key={game.id}>
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to={`/${slug}`}>
            {name}
          </ClickableLink>
        </li>,
      )
    })
  }

  if (selectedGame?.id || activeGame?.id) {
    const thisGame = selectedGame?.id ? selectedGame : activeGame
    if (pageExists(`${activeGame?.info.slug}/dashboard`) && activeGame?.id === thisGame?.id) {
      linkItems.push(
        <li key="dashboard-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/dashboard">
            {t('components.nav.dashboard')}
          </ClickableLink>
        </li>,
      )
    }

    if (!activeGame || !activeGame.id) {
      linkItems.push(
        <li key="landing-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/">
            {t('components.nav.allGames')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/leaderboards`)) {
      linkItems.push(
        <li key="leaderboards-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/leaderboards">
            {t('components.nav.leaderboards')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/events`)) {
      linkItems.push(
        <li key="events-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/events">
            {t('components.nav.events')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/activities`)) {
      linkItems.push(
        <li key="activities-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/activities">
            {t('components.nav.activities')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/how-to-play`)) {
      linkItems.push(
        <li key="how-to-play-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/how-to-play">
            {t('components.nav.howTo')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/faqs`)) {
      linkItems.push(
        <li key="faqs-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/faqs">
            {t('components.nav.faq')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/awards`) && isAuthenticated && activeGame?.id === thisGame?.id) {
      linkItems.push(
        <li key="awards-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/awards">
            {t('components.nav.awards')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`resources-for-schools`)) {
      linkItems.push(
        <li key="resources-for-schools-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/resources-for-schools">
            {t('components.nav.resourcesForSchools')}
          </ClickableLink>
        </li>,
      )
    }

    if (pageExists(`${thisGame?.info.slug}/partners`)) {
      linkItems.push(
        <li key="partners-link">
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/partners">
            {t('components.nav.partners')}
          </ClickableLink>
        </li>,
      )
    }
    // todo take from game setting https://intelligenthealth.atlassian.net/browse/BD-776
    if (!['eastkilbride', 'clydesdale'].includes(`${thisGame?.info.slug}`)) {
      linkItems.push(
        <li key="shop-link" style={{ display: 'flex' }}>
          <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/shop">
            {t('components.nav.shop')}
          </ClickableLink>
          <span style={{ fontSize: 25, marginLeft: 10 }} role="img" aria-label={t('aria.label.truckIcon') || ''}>
            &#128722;
          </span>
        </li>,
      )
    }
  }

  if (!user && !isAuthenticated) {
    linkItems.push(
      <li key="register-link">
        <ClickableLink setIsNavOpen={setIsNavOpen} to="/signup" tabIndex={-1}>
          <NavButton text={t('signUp.title')} />
        </ClickableLink>
      </li>,
    )

    linkItems.push(
      <li key="login-link">
        <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={-1} to="/login">
          <NavButton secondary text={t('auth.login')} />
        </ClickableLink>
      </li>,
    )
  }

  if (user && isAuthenticated) {
    linkItems.push(
      <li key="manage-account-link">
        <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={isNavOpen ? 0 : -1} to="/account">
          {t('components.nav.manageAccount')}
        </ClickableLink>
      </li>,
    )

    linkItems.push(
      <li key="logout-link">
        <ClickableLink setIsNavOpen={setIsNavOpen} tabIndex={-1} to="/">
          <NavButton
            white
            text={t('components.nav.logOut')}
            onClick={async () => {
              await logout()
              // changeLanguage(LanguageCodes['en-GB'])
            }}
          />
        </ClickableLink>
      </li>,
    )
  }

  return (
    <NavContainer style={backgroundStyles} isNavOpen={isNavOpen} aria-expanded={!!isNavOpen}>
      <div>
        <NavCircleTop>
          <animated.div style={navStyles}>
            <StyledNav>
              <nav aria-label={t('aria.label.mainNav') || 'nav Item'}>
                <ul>{linkItems}</ul>
              </nav>
            </StyledNav>
            <NavIconWrapper>
              <NavIcon style={iconStyles}>
                <LamppostIcon />
              </NavIcon>
            </NavIconWrapper>
          </animated.div>
        </NavCircleTop>
        <NavCircle />
      </div>

      <NavBackground style={backgroundStyles} />
    </NavContainer>
  )
}

export default Nav
