import React from 'react'
import CTAGrid from '../../../../components/CTAGrid'
import CallToActionPanel from '../../../../components/CallToAction'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameWithContent } from '../../../../contexts/PageContext'
import { User } from '../../../../contexts/AuthContext/type'
import { differenceInCalendarDays } from 'date-fns'

interface IProps {
  game: GameWithContent
  user?: User
}

//game.extra.isLastWeek
function CTAs({ game, user }: IProps) {
  const { t } = useTranslation()

  const isEnding = differenceInCalendarDays(new Date(game?.info.endsAt), new Date()) <= (game?.info.restrictAtDays || 0)

  return (
    <CTAGrid>
      {!user && !isEnding ? (
        <CallToActionPanel
          style="white"
          title={t('components.CTA.register.title') || ''}
          link={{ linkText: t('components.CTA.register.linkText') || '', internalLink: '/signup' }}
          icon="girl_wellies"
        />
      ) : null}
      <CallToActionPanel
        title={
          game.info.name
            ? t('components.CTA.map.titleWithName', { gameName: game.info.name }) || ''
            : t('components.CTA.map.title') || ''
        }
        style="dark"
        link={{ linkText: t('components.CTA.map.linkText') || '', internalLink: '/map' }}
        icon="handbag_woman"
      />
      {/*{user*/}
      {/*  ? game.content.ctaListForLoggedInUsers?.map((v) => <CallToActionPanel key={v?.id} {...v} context={{ game }} />)*/}
      {/*  : game.content.ctaListForLoggedOutUsers?.map((v) => (*/}
      {/*      <CallToActionPanel key={v?.id} {...v} context={{ game }} />*/}
      {/*    ))}*/}
    </CTAGrid>
  )
}

export default CTAs
