import { StyledCard, StyledCardContainer } from '../../components/Card/Card.styled'
import styled from 'styled-components'
import device from '../../styles/device'

export const MainHeading = styled.h1`
  text-align: center;
  font-size: 30px;
  margin: -20px 30px 40px 30px;
  @media ${device.tabletPortrait} {
    font-size: 50px;
    margin-bottom: 50px;
  }
`

export const SubHeading = styled('p')`
  max-width: ${(props) => props.theme.siteWidth};
  padding: 0 60px 100px;
  margin: auto;
  text-align: center;
  color: ${(props) => props.theme.colors.darkGrey};
`

export const Wrapper = styled('div')`
  background-color: rgb(53, 152, 170);
  min-height: 300px;

  > div {
    max-width: 900px;
    margin: -120px auto 0;
    padding-bottom: 50px;

    @media ${device.tabletPortrait} {
      margin-top: -50px;
      transform: translateY(-120px);
      padding-bottom: 0px;
    }
  }
  h2 {
    font-size: 2.2rem;
  }
  position: relative;
  margin: 150px auto;
  @media ${device.mobLandscape} {
    margin: 150px auto;
  }

  @media ${device.tabletPortrait} {
    min-height: 600px;
    margin: 200px auto;
  }

  @media ${device.desktop} {
    min-height: 800px;
  }

  > svg {
    position: absolute;
    z-index: -1;
    top: -50px;
    left: -13%;
    width: 126%;
    @media ${device.tabletPortrait} {
      top: -80px;
      left: 0;
      width: 100%;
    }
  }
`

export const StyledCardShop = styled(StyledCard)`
  > div {
    max-width: 320px;
    min-height: 250px;
  }

  @media ${device.tabletPortrait} {
    > div {
      max-width: 900px;
      min-height: 290px;
    }
  }
`

export const StyledCardContainerShop = styled(StyledCardContainer)`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
`

export const StyledImg = styled.img`
  object-fit: cover;
  height: auto;
  min-height: 200px;
  width: 100%;

  @media ${device.tabletPortrait} {
    max-width: 30%;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 1.8rem;
  }

  @media ${device.tabletPortrait} {
    flex-direction: row;

    h2 {
      font-size: 2.2rem;
    }
  }
`

export const WrapperContainer = styled.div`
  > div {
    margin-top: 100px;
  }
`
