import React, { FC } from 'react'
import { ExternalLinkButton, InternalLinkButton } from './LinkButton.styled'
import { Links } from '../ContextLink'

export interface LinkButtonProps {
  to: Links | string
  external?: boolean
  children: string | JSX.Element | JSX.Element[]
  secondary?: boolean
  tertiary?: boolean
  replace?: boolean
  primarySmall?: boolean
  target?: string
  rel?: string
  primary?: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

const LinkButton: FC<LinkButtonProps> = ({ to, external, children, ...props }) => {
  return (
    <>
      {external ? (
        <ExternalLinkButton href={to} target="_blank" {...props} rel="noopener noreferrer">
          {children}
        </ExternalLinkButton>
      ) : (
        <InternalLinkButton to={to} {...props}>
          {children}
        </InternalLinkButton>
      )}
    </>
  )
}

export default LinkButton
