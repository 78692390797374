import styled from 'styled-components'
import { withProp } from 'styled-tools'
import theme from '../../../../../styles/theme'

const Themes = {
  outdoorGym: theme.colors.pink,
  distributionPoint: theme.colors.green,
  beatBox: theme.colors.primary,
  school: theme.colors.body,
  gem: theme.colors.lightPink,
}

export const PopupButton = styled.a`
  font-size: 14px;
  display: inline-block;
  width: auto;
  position: relative;
  padding: 5px 30px 5px 10px;
  background: ${withProp('inverted', (inverted) => (inverted ? theme.colors.white : theme.colors.secondary))};
  color: ${withProp('inverted', (inverted) => (inverted ? theme.colors.secondary : theme.colors.white))};
  border-radius: 6px;
  svg {
    position: absolute;
    width: 15px;
    height: 12px;
    right: 5px;
    top: 10px;
  }
`

export const InnerWrapper = styled.div`
  width: 300px;
  border-radius: 5px;
  overflow: hidden;
  background: ${(props) => props.theme.colors.white};
`

export const PopupContent = styled.div`
  padding: 10px;
  p {
    font-size: 1.5rem;
  }
`

export const PopupHeader = styled.div`
  background: ${withProp('type', (type) => Themes[type] || theme.colors.primary)};
  * {
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.fonts.primaryFont};
  }
  padding: 8px 13px 6px 5px;
  position: relative;
  display: flex;
  align-items: center;
  > svg {
    display: inline-block;
    height: 30px;
  }
  h3 {
    display: inline-block;
    font-weight: normal;
    font-size: 1.7rem;
    line-height: 2rem;
    margin: 1px ${(props) => (props.accessible ? '45px' : '15px')} 6px 5px;
    padding: 0;
  }
  svg path {
    fill: ${(props) => props.theme.colors.white};
  }
  button {
    position: absolute;
    top: 0;
    right: 10px;
    pointer-events: all;
    height: 100%;
    display: flex;
    align-items: center;
    background: none;
    padding: 0;
    margin: 0;
    svg {
      height: 10px;
    }
  }
  div#accessible {
    position: absolute;
    top: 0;
    right: 25px;
    height: 100%;
    display: ${(props) => (props.accessible ? 'flex' : 'none')};
    align-items: center;
    background: none;
    svg {
      height: 40px;
      width: 40px;
    }
  }
`
