import React, { useEffect } from 'react'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import Map, { Layer, Marker, Source } from 'react-map-gl'
import FilterBox from './FilterBox/FilterBox'
import Popup from './Popup/Popup'
import { MapBoxWrapper, MapFooter } from './Mapbox.styled'
import 'mapbox-gl/dist/mapbox-gl.css'
import MapSearchBox from '../../../../components/MapSearchBox/MapSearchBox'
import SVGImage from '../../../../components/DynamicImg/SVGImage'
import { LocationTypes } from '../../../../generated/api'
import useMapIcons from './useMapIcons'
import { ViewState } from 'react-map-gl/src/types/external'
import { useMapState } from './Context'
import useLocations from './useLocations'
import Loading from '../../../../components/Loading'
import { values } from 'lodash'
import useGame from '../../../../contexts/GameContext/useGame'
import formatBoundingBox from './formatBoundingBox'

const MAP_TILES_URL = 'https://maps.intelligenthealth.co.uk/style.json'
const ICON_SIZE = 0.3

const Mapbox = ({ reportEmail }) => {
  const { geoJsonData, isLoading } = useLocations()
  const {
    mapRef,
    setIsMapLoaded,
    isMapLoaded,
    filter,
    popup,
    setPopup,
    viewState,
    setViewState,
    searchValue,
    isExpanded,
    setIsExpanded,
    tempPointCoords,
  } = useMapState()
  const { registerIconsOnLoad } = useMapIcons()
  const { game } = useGame()

  useEffect(() => {
    if (isExpanded && popup.isVisible) {
      setPopup({ isVisible: false, feature: null, type: null })
    }
  }, [isExpanded])

  useEffect(() => {
    if (typeof document !== 'undefined') {
      const attribution = document?.querySelector('.mapboxgl-ctrl-attrib-inner')
      attribution?.setAttribute('aria-hidden', 'true')
    }
  }, [])

  useEffect(() => {
    if (geoJsonData && geoJsonData?.features?.length > 0 && !viewState?.latitude) {
      setViewState((prev: ViewState) => ({
        ...(prev || {}),
        longitude: geoJsonData.features[0].geometry.coordinates[0],
        latitude: geoJsonData.features[0].geometry.coordinates[1],
      }))
    }
  }, [geoJsonData])

  useEffect(() => {
    if (game?.info.boundingBox && game?.info.boundingBox !== '0,0,0,0') {
      const formatted = formatBoundingBox(game.info.boundingBox)

      mapRef.current?.fitBounds(formatted, { maxDuration: 1 })
    }
  }, [game?.info.boundingBox, mapRef.current])

  const handleLayerClick = (ev) => {
    const feature = ev.features?.[0]
    if (feature) {
      setPopup({ isVisible: true, feature: feature, type: feature.properties.type || null })
      setViewState((prev: ViewState) => ({
        ...(prev || {}),
        longitude: feature.geometry.coordinates[0],
        latitude: feature.geometry.coordinates[1],
        zoom: 15,
      }))
      setIsExpanded(false)
    } else {
      setPopup({ isVisible: false, feature: null, type: null })
    }
  }

  const hover = () => {
    values(LocationTypes).map((type) => {
      mapRef.current?.on('mousemove', type, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = 'pointer'
        }
      })
    })
  }

  const leave = () => {
    values(LocationTypes).map((type) => {
      mapRef.current?.on('mouseleave', type, () => {
        if (mapRef.current) {
          mapRef.current.getCanvas().style.cursor = 'grab'
        }
      })
    })
  }

  if (isLoading && !game) return <Loading />
  return (
    <MapBoxWrapper>
      <Map
        id={'beatthestreet-map'}
        ref={mapRef}
        mapLib={maplibregl}
        trackResize
        doubleClickZoom
        minZoom={6}
        mapStyle={MAP_TILES_URL}
        zoom={viewState?.zoom || 12.5}
        interactiveLayerIds={Object.values(LocationTypes)}
        onMove={(event) => {
          setViewState(event.viewState)
        }}
        latitude={viewState?.latitude || 0}
        longitude={viewState?.longitude || 0}
        onClick={handleLayerClick}
        style={{
          flexGrow: 1,
          filter: `grayscale(${isMapLoaded ? 0 : 100}%)`,
          border: '1px solid #DEDEDE',
          borderLeft: 0,
        }}
        onLoad={registerIconsOnLoad}
        onStyleData={() => setIsMapLoaded(true)}
        onMouseMove={hover}
        onMouseLeave={leave}
      >
        {tempPointCoords?.lat && searchValue && (
          <Marker latitude={tempPointCoords?.lat} longitude={tempPointCoords?.lng} anchor="bottom">
            <SVGImage filename="marker.svg" svgProperties={{ width: '40px', height: '40px' }} />
          </Marker>
        )}

        {geoJsonData && (
          <>
            {isMapLoaded && (
              <>
                <Source key="bts" id="bts" type="geojson" data={geoJsonData} />
                <Layer
                  key={LocationTypes.BeatBox}
                  id={LocationTypes.BeatBox}
                  type="symbol"
                  source="bts"
                  interactive
                  layout={{
                    'icon-image': 'beatbox',
                    ...layoutProperties,
                  }}
                  filter={[
                    '==',
                    ['get', 'type'],
                    filter.beatBoxes ? 'beatBox' : 'hide',
                    ['collator', { 'case-sensitive': false }],
                  ]}
                />

                <Layer
                  key={LocationTypes.School}
                  id={LocationTypes.School}
                  type="symbol"
                  source="bts"
                  interactive
                  layout={{
                    'icon-image': 'school',
                    ...layoutProperties,
                  }}
                  filter={[
                    '==',
                    ['get', 'type'],
                    filter.schools ? 'school' : 'hide',
                    ['collator', { 'case-sensitive': false }],
                  ]}
                />

                <Layer
                  key={LocationTypes.DistributionPoint}
                  id={LocationTypes.DistributionPoint}
                  type="symbol"
                  source="bts"
                  interactive
                  layout={{
                    'icon-image': 'card-pickup-point',
                    ...layoutProperties,
                  }}
                  filter={[
                    '==',
                    ['get', 'type'],
                    filter.distributionPoints ? 'distributionPoint' : 'hide',
                    ['collator', { 'case-sensitive': false }],
                  ]}
                />

                <Layer
                  key={LocationTypes.Gem}
                  id={LocationTypes.Gem}
                  type="symbol"
                  source="bts"
                  interactive
                  layout={{
                    'icon-image': 'gem',
                    ...layoutProperties,
                  }}
                  filter={[
                    '==',
                    ['get', 'type'],
                    filter.gems ? 'gem' : 'hide',
                    ['collator', { 'case-sensitive': false }],
                  ]}
                />

                <Layer
                  key={LocationTypes.OutdoorGym}
                  id={LocationTypes.OutdoorGym}
                  type="symbol"
                  source="bts"
                  interactive
                  layout={{
                    'icon-image': 'outdoor-gym',
                    ...layoutProperties,
                  }}
                  filter={[
                    '==',
                    ['get', 'type'],
                    filter.outdoorGyms ? 'outdoorGym' : 'hide',
                    ['collator', { 'case-sensitive': false }],
                  ]}
                />
              </>
            )}
            {popup.isVisible && popup.feature && <Popup reportEmail={reportEmail} />}
          </>
        )}
      </Map>
      <MapSearchBox />
      <FilterBox />
      <MapFooter />
    </MapBoxWrapper>
  )
}

export default Mapbox

const layoutProperties = {
  'icon-size': ICON_SIZE,
  'icon-allow-overlap': true,
  'icon-ignore-placement': true,
  'text-ignore-placement': true,
  'icon-anchor': 'bottom' as const,
}
