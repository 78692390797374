import React, { FC, useEffect, useState } from 'react'
import SEO from '../../components/seo'
import Input from '../../components/Input/Input'
import { CustomLink } from '../../components/Link/Link.styled'
import LinkButton from '../../components/LinkButton/LinkButton'
import { Heading } from '../../components/Heading/Heading.styled'
import { SubHeading } from '../../components/SubHeading/SubHeading.styled'
import Wrapper from '../../components/Wrapper/Wrapper'
import { Error, FormItem } from '../../components/FormElements/FormElements.styled'
import RecaptureTerms from '../../components/RecaptureTerms/RecaptureTerms'
import { ErrorMessage, Formik } from 'formik'
import Button from '../../components/Button/Button'
import useAuth from '../../contexts/AuthContext/useAuth'
import useNavigate from '../../hooks/useNavigate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
// import { LanguageCodes } from '../../graphql/language'
import useGame from '../../contexts/GameContext/useGame'
import usePageContext from '../../hooks/usePageContext'
import { getLoginSchema } from './validation'
import { IPageProps } from '../../contexts/PageContext'

const LoginPage: FC<IPageProps<Queries.LoginPageQuery>> = () => {
  const query = new URLSearchParams(typeof location !== 'undefined' ? location?.search : '')
  const nextUrl = query.get('next')

  const { login, user, isAuthenticated } = useAuth()
  const { activeGame: game } = useGame()
  const { allGames } = usePageContext()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const loginSchema = getLoginSchema(t)
  const [familyUrl, setFamilytUrl] = useState<string | null>(null)
  // const { changeLanguage } = useI18next()

  useEffect(() => {
    setFamilytUrl(nextUrl)
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!user && !isAuthenticated) return
      if (!user?.info.publicName) {
        return navigate('/signup/name-details')
      }

      const userGame = allGames?.find((game) => game?.id === user?.info.gameId)
      if (!familyUrl && userGame?.info.slug) {
        return navigate('/landing')
      }
      if (familyUrl) {
        return navigate(familyUrl)
      }

      return navigate('/')
    })()
  }, [user, isAuthenticated, allGames, familyUrl, navigate])

  return (
    <>
      <SEO title={t('auth.login')} />
      <Heading secondary tall data-testid="page-title">
        {t('auth.login')}
      </Heading>
      <Wrapper width="400px" marginTop="1rem">
        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: null,
          }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setErrors }) => {
            try {
              await login(values.email, values.password)
              if (familyUrl) {
                return await navigate(familyUrl)
              }
              return navigate('/')
            } catch (e) {
              console.log('e==', e)
              const errorMessage = e.message
              if (errorMessage.includes('Password attempts')) {
                setErrors({ submit: t('auth.errors.timeExceeded') || undefined })
              } else if (errorMessage.includes('User does not exist')) {
                setErrors({ submit: t('auth.errors.noUser') || undefined })
              } else if (errorMessage.includes('Incorrect username')) {
                setErrors({ submit: t('auth.errors.wrongUserName') || undefined })
              } else {
                setErrors({ submit: errorMessage || t('errors.generic') })
              }
            }
          }}
        >
          {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, touched }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <FormItem marginBottom="4.8rem">
                  <Input
                    type="text"
                    id="email"
                    text={t('common.email') || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email ? errors.email : undefined}
                    maxlength={50}
                    value={values.email}
                    autoComplete="email"
                    required
                    inputProps={{ 'data-testid': 'email-input' }}
                  />
                </FormItem>
                <FormItem marginBottom="4.8rem">
                  <Input
                    type="password"
                    id="password"
                    text={t('common.password') || ''}
                    autoComplete="current-password"
                    maxlength={50}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.password ? errors.password : undefined}
                    value={values.password}
                    required
                    hasPassToggle
                    inputProps={{ 'data-testid': 'password-input' }}
                  />
                  <CustomLink to="/forgot-password/" placement="right-aligned" data-testid="forgot-password-link">
                    {t('auth.forgotPassword')}
                  </CustomLink>
                </FormItem>
                <ErrorMessage
                  name="submit"
                  component={(props) => (
                    <>
                      <Error className="checkboxError" data-testid="error-msg" {...props} />
                      <br />
                    </>
                  )}
                />
                <Button
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  text={t('common.continue')}
                  secondary
                  testID="continue-button"
                  type={'submit'}
                />
              </form>
            )
          }}
        </Formik>
        <RecaptureTerms />
      </Wrapper>

      <Wrapper width="500px" marginTop="4rem">
        <SubHeading as="h2" tertiaryForm small data-testid="no-account-title">
          {t('auth.newToBTS')}
        </SubHeading>
      </Wrapper>
      <Wrapper veryTall>
        <LinkButton to="/signup" tertiary data-testid="create-account-button">
          <span>{t('auth.newAccount')}</span>
        </LinkButton>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query LoginPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default LoginPage
