import React, { useState } from 'react'
import CarouselCard from './CarouselCard'
import { TABLETWIDTH } from '../../../containers/GameStats/GameStats.styled'
import SwipeableContent from '../../../components/SwipeableContent'
import SwipeableHowToPlay from './SwipableHowToPlay'
import { useSwipeable } from 'react-swipeable'

const contentMock = [
  {
    title: 'Collect your card',
    content: 'Pick up your card from your school or local distribution point.',
    image: 'carousel-one',
  },
  {
    title: 'Sign up and register your card',
    content: 'Create a Beat the Street account and register your card',
    image: 'carousel-two',
  },
  {
    title: 'Explore your local area',
    content:
      'Explore your local area and find Beat Boxes. You can locate them by using our online map, or through the interactive map on our mobile app. ',
    image: 'carousel-three',
  },
  {
    title: 'Collect points',
    content:
      'Contactless sensors called Beat Boxes are placed on lamp posts around your area. Find your nearest Beat Box and hover your card until it beeps and flashes. This starts your journey! Walk, cycle or wheel to your next Beat Box to collect points. \n' +
      'Visit two Beat Boxes within an hour to collect 10 points for each Beat Box - this is 20 points for your journey. Carry on your journey and score 10 points for each extra Beat Box you visit. ',
    image: 'carousel-four',
  },
  {
    title: 'Compete to win awards',
    content:
      'Compete against friends, community groups and schools to win prizes and earn awards. See how they compare on the leaderboards in real time. ',
    image: 'carousel-five',
  },
]

const Carousel = () => {
  const [currentPage, setCurrentPage] = useState(0)

  const handleSwipe = (increment) => {
    setCurrentPage(Math.min(Math.max(0, currentPage + increment), contentMock.length - 1))
  }
  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe(1),
    onSwipedRight: () => handleSwipe(-1),
    trackMouse: true,
  })

  const content: JSX.Element[] = []

  contentMock.map((cardContent, index) =>
    content.push(
      <CarouselCard
        key={index}
        title={cardContent.title}
        image={cardContent.image}
        content={cardContent.content}
        steps={contentMock.length}
        step={index}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />,
    ),
  )

  return (
    <SwipeableHowToPlay
      isScrollable={false}
      mobileOnly={false}
      pages={content}
      handlers={handlers}
      currentPage={currentPage}
    />
  )
}

export default Carousel
