import React from 'react'
import theme from '../../styles/theme'
import styled from 'styled-components'
import Check from '../../assets/icons/Check'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const StepNumber = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${theme.colors.body};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;

  p {
    margin: 0;
    color: white;
    font-size: 1.5rem;
  }
`

const FinishedStepNumber = styled(StepNumber)`
  background-color: ${theme.colors.primary};
`

const NotFinishedStepNumber = styled(StepNumber)`
  background-color: transparent;
  border: 1px solid ${theme.colors.darkGrey};

  p {
    margin: 0;
    color: ${theme.colors.darkGrey};
    font-size: 1.5rem;
  }
`

const StepText = styled.p`
  margin: 0 0 0 10px;
  font-size: 1.3rem;
  font-weight: bold;
  color: ${theme.colors.body};
`

const GreyStepText = styled(StepText)`
  color: ${theme.colors.darkGrey};
`

const StepLine = styled.div`
  width: 50px;
  height: 1px;
  border: 1px solid ${theme.colors.grey};
  margin-left: 10px;
  margin-right: 10px;
`

enum StepperState {
  current = 'current',
  before = 'before',
  after = 'after',
}

const Step = ({ text, step, state }) => {
  if (state === StepperState.before) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <NotFinishedStepNumber>
          <p>{step}</p>
        </NotFinishedStepNumber>
        <GreyStepText>{text}</GreyStepText>
      </div>
    )
  }

  if (state === StepperState.after) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <FinishedStepNumber>
          <Check />
        </FinishedStepNumber>
        <GreyStepText>{text}</GreyStepText>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <StepNumber>
        <p>{step}</p>
      </StepNumber>
      <StepText>{text}</StepText>
    </div>
  )
}

const Stepper = ({ currentStep }) => {
  const { t } = useTranslation()
  const steps = [{ text: t('gameSetup.steps.stepOne') }, { text: t('gameSetup.steps.stepTwo') }]
  return (
    <>
      {steps.map((step, index, arr) => {
        const state =
          currentStep === index + 1
            ? StepperState.current
            : currentStep < index + 1
            ? StepperState.before
            : StepperState.after
        return (
          <>
            <Step text={step.text} step={index + 1} state={state} />
            {index + 1 !== arr.length && <StepLine />}
          </>
        )
      })}
    </>
  )
}

export default Stepper
