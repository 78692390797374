import { graphql, useStaticQuery } from 'gatsby'
import { Background, Bar, Container, IconContainer, MessageContainer, Text } from '../Message/Message.styled'
import Warning from '../../assets/images/svg/warning.svg'
import React from 'react'
import Wrapper from '../Wrapper/Wrapper'

const JoinGameMessage = () => {
  const { sanityRegistrationSettings } = useStaticQuery(graphql`
    query {
      sanityRegistrationSettings(_id: { regex: "/(drafts.|)registrationSettings/" }, i18n_lang: { eq: "en-GB" }) {
        joinGameMessage
      }
    }
  `)

  const message = sanityRegistrationSettings?.joinGameMessage

  if (!message) return null

  return (
    <Wrapper style={{ display: 'flex', justifyContent: 'center' }} marginBottom={'4rem'}>
      <Background show={true}>
        <Bar type="warning" />
        <IconContainer style={{ height: 53, width: 36, marginRight: '1rem' }}>
          <Warning id="warning-icon" />
        </IconContainer>
        <MessageContainer>
          <div>
            <Text style={{ fontSize: 18 }}>{message}</Text>
          </div>
        </MessageContainer>
      </Background>
    </Wrapper>
  )
}

export default JoinGameMessage
