import React from 'react'
import Wrapper from '../../../../../components/Wrapper/Wrapper'
import Spacer from '../../../../../components/Spacer'
import RichText from '../../../../../components/RichText'
import { graphql, useStaticQuery } from 'gatsby'
import useGame from '../../../../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import LeaderboardLink from './LeaderboardLink'

const FinishedGameContent = () => {
  const { game } = useGame()
  const { t } = useTranslation()

  const { sanityGlobalSettings: globalSettings } = useStaticQuery(graphql`
    query ($language: String) {
      sanityGlobalSettings(_id: { regex: "/(drafts.|)globalSettings/" }, i18n_lang: { eq: $language }) {
        endOfGameIntroductionText: _rawEndOfGameIntroductionText
        endOfGameWinnersText: _rawEndOfGameWinnersText
      }
    }
  `)

  const globalWinnersText = globalSettings?.endOfGameWinnersText
  const introductionText = globalSettings?.endOfGameIntroductionText
  const gameWinnersText = game?.content?.endOfGameWinnersText
  const showWinners = game?.content?.endOfGameShowWinners

  return (
    <>
      <Wrapper width="690px">
        <Spacer space="10rem" />
        {!showWinners && introductionText?.length > 0 && (
          <RichText
            blocks={introductionText}
            context={{
              game,
            }}
          />
        )}
        {showWinners && globalWinnersText?.length > 0 && !(gameWinnersText?.length > 0) && (
          <>
            <RichText
              blocks={globalWinnersText}
              context={{
                game,
              }}
            />
            <Spacer space="4rem" />
          </>
        )}
        {showWinners && gameWinnersText?.length > 0 && (
          <RichText
            blocks={gameWinnersText}
            context={{
              game,
            }}
          />
        )}
        {showWinners && (
          <>
            <LeaderboardLink to={`/leaderboards`} primary replace>
              {t('game.leaderboards.linkText')}
            </LeaderboardLink>
            <Spacer space="4rem" />
          </>
        )}
      </Wrapper>
    </>
  )
}

export default FinishedGameContent
