import { HeadingWrapper } from '../HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../Heading/Heading.styled'
import Wrapper from '../Wrapper/Wrapper'
import { Text } from '../Message/Message.styled'
import LinkButton from '../LinkButton/LinkButton'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useAuth from '../../contexts/AuthContext/useAuth'
import useGameRestricted from '../../hooks/useGameRestricted'

interface Props {
  title: string
  text: string
}

const RestrictedPage = ({ title, text }: Props) => {
  const { t } = useTranslation()
  const { masterUser } = useAuth()
  const { game } = useGameRestricted()

  return (
    <>
      <HeadingWrapper>
        <Heading>{title}</Heading>
      </HeadingWrapper>
      <Wrapper marginBottom="4rem" marginTop="2rem" width="500px" textAlign="left">
        <Text style={{ fontSize: 20, fontWeight: 'normal', textAlign: 'center' }}>{text}</Text>
      </Wrapper>
      <Wrapper width="320px">
        <LinkButton to={game?.id && masterUser?.info.gameId ? '/dashboard' : '/'} primary replace>
          {t('signUp.success.goToButton.goTo', {
            place:
              game?.id && masterUser?.info.gameId
                ? t('signUp.success.goToButton.dashboard')
                : t('signUp.success.goToButton.home'),
          }) || ''}
        </LinkButton>
      </Wrapper>
    </>
  )
}

export default RestrictedPage
