import React, { FC } from 'react'
import Wrapper from '../../../components/Wrapper/Wrapper'
import SEO from '../../../components/seo'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import LinkButton from '../../../components/LinkButton/LinkButton'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useLocation } from '@gatsbyjs/reach-router'
import { IPageProps } from '../../../contexts/PageContext'

const SelectAge: FC<IPageProps<Queries.SelectAgeQuery>> = () => {
  const { t } = useTranslation()
  const { search } = useLocation()

  let familyId = ''

  if (typeof window !== 'undefined') {
    familyId = new URLSearchParams(search).get('id') || ''
  }

  const setAge = () => {
    sessionStorage.setItem('ageSelected', 'over13')
  }

  return (
    <>
      <SEO title={t('signUp.selectAge.seoTitle')} />
      <HeadingWrapper>
        <Heading secondary tall marginTop={'1rem'} data-testid="page-title">
          {t('signUp.title')}
        </Heading>
        <SubHeading as="h5" veryTall tertiarySmall data-testid="page-subtitle">
          {t('signUp.selectAge.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="center" marginTop={'1rem'}>
        <LinkButton to="/signup/under-age/" tertiary replace data-testid="under-13-button">
          {t('signUp.selectAge.under') || ''}
        </LinkButton>
      </Wrapper>
      <Wrapper marginTop={'20px'}>
        <div style={{ marginBottom: 100 }}>
          <LinkButton
            to={familyId ? `/signup/create-account/?id=${familyId}` : `/signup/create-account`}
            secondary
            replace
            data-testid="over-13-button"
            onClick={setAge}
          >
            {t('signUp.selectAge.over') || ''}
          </LinkButton>
        </div>
      </Wrapper>
    </>
  )
}

export default SelectAge
export const query = graphql`
  query SelectAge($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`
