import React, { FC } from 'react'
import Wrapper from '../../../components/Wrapper/Wrapper'
import SEO from '../../../components/seo'
import { HeadingWrapper } from '../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../components/SubHeading/SubHeading.styled'
import LinkButton from '../../../components/LinkButton/LinkButton'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import useGameRestricted from '../../../hooks/useGameRestricted'
import Loading from '../../../components/Loading'
import RestrictedPage from '../../../components/RestrictedPage'

const SelectFamilyMemberAge: FC<IPageProps<Queries.SelectFamilyMemberAgeQuery>> = () => {
  const { t } = useTranslation()
  const { isGameRestricted, isLoading, game } = useGameRestricted()

  if (isLoading) {
    return <Loading />
  }

  if (isGameRestricted) {
    return (
      <RestrictedPage
        title={t('addFamily.title')}
        text={t('signUp.selectAge.restrictedPage', { days: game?.info.restrictAtDays })}
      />
    )
  }

  return (
    <>
      <SEO title={t('addFamily.title')} />
      <HeadingWrapper>
        <Heading secondary tall marginTop={'1rem'}>
          {t('addFamily.title')}
        </Heading>
        <SubHeading as="h5" veryTall tertiarySmall>
          {t('addFamily.subTitle')}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="center" marginTop={'1rem'}>
        <LinkButton to="/signup/family/details" tertiary replace>
          {t('signUp.selectAge.under') || ''}
        </LinkButton>
      </Wrapper>
      <Wrapper marginTop={'20px'}>
        <div style={{ marginBottom: 100 }}>
          <LinkButton to="/signup/family/invite-family" secondary replace>
            {t('signUp.selectAge.over') || ''}
          </LinkButton>
        </div>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query SelectFamilyMemberAge($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default SelectFamilyMemberAge
