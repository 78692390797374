import React, { FC } from 'react'
import Spacer from '../Spacer'
import { StyledTapListDay, TapListContainer } from '../TapListDay/TapListDay.styled'
import { TapList } from '../TapListItem/TapListItem.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import CallToActionPanel from '../CallToAction/CallToActionPanel'
import { GameWithContent } from '../../contexts/PageContext'

interface TapListEmptyProps {
  game: GameWithContent
}

const TapListEmpty: FC<TapListEmptyProps> = ({ game }) => {
  const { t } = useTranslation()

  return (
    <TapList>
      <TapListContainer>
        <StyledTapListDay>{t('game.dashboard.stats.journeyModal.noActivity')}</StyledTapListDay>
        <Spacer space="100px" />
        <CallToActionPanel
          context={{ game }}
          title={t('components.CTA.firstJourney.title')}
          text={t('components.CTA.firstJourney.text')}
          style="light"
          link={{ linkText: t('components.CTA.firstJourney.linkText'), internalLink: '/map' }}
          icon="letterbox_path"
        />
      </TapListContainer>
    </TapList>
  )
}

export default TapListEmpty
