import React, { FC } from 'react'
import { FooterLink } from '../FooterWithBackground/FooterWithBackground.styled'
import { StyledFooter, FooterInner, FooterLinks } from './FooterSimple.styled'
import ContextLink from '../../ContextLink'
import usePageContext from '../../../hooks/usePageContext'
import theme from '../../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
// import ChangeLanguage from '../../ChangeLanguage'

interface FooterProps {
  isGamePage?: boolean
  className?: string | unknown
}

const FooterSimple: FC<FooterProps> = ({ isGamePage, className }) => {
  const { pageList } = usePageContext()
  const { t } = useTranslation()
  const pageExists = (page: string) => pageList?.some((x) => x.includes(page))

  return (
    <StyledFooter className={className} id="footer-simple-container">
      <FooterInner id="footer-simple-inner">
        <p>
          &copy;
          {` ${t('components.footer.intelligentHealth', {
            date: new Date().getFullYear(),
          })}`}
        </p>
        <FooterLinks>
          {pageExists('about-us') && (
            <FooterLink>
              <ContextLink to="/about-us">{t('components.footer.aboutUs')}</ContextLink>
            </FooterLink>
          )}
          {pageExists('terms-and-conditions') && (
            <FooterLink>
              <ContextLink to="/terms-and-conditions">{t('components.footer.tAndC')}</ContextLink>
            </FooterLink>
          )}
          {pageExists('privacy') && (
            <FooterLink>
              <ContextLink to="/privacy">{t('components.footer.privacy')}</ContextLink>
            </FooterLink>
          )}
          {pageExists('accessibility') && (
            <FooterLink>
              <ContextLink to="/accessibility" style={{ color: theme.colors.midDarkBlue }}>
                {t('components.footer.accessibility')}
              </ContextLink>
            </FooterLink>
          )}

          {/*<ChangeLanguage isGamePage={isGamePage} />*/}
        </FooterLinks>
      </FooterInner>
    </StyledFooter>
  )
}

export default FooterSimple
