import React from 'react'
import styled from 'styled-components'

export const Wrapper = styled.div`
  h2 {
    font-size: 4rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 8px 5px -5px rgba(0, 0, 0, 0.05);
  }
  svg {
    margin: 0 auto 6px;
  }
  padding: 20px;
`

export const ScrollableArea = styled.div`
  width: 100%;
  height: 400px;
  overflow-x: hidden;
  overflow-y: scroll;
`
export const HowItWorks = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}
