import React from 'react'
import Wrapper from '../../components/Wrapper/Wrapper'
import { HeadingWrapper } from '../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../components/Heading/Heading.styled'
import LinkButton from '../../components/LinkButton/LinkButton'
import useAuth from '../../contexts/AuthContext/useAuth'
import JoinGameCTA from '../../components/JoinGameCTA'
//import Surveys from '../game/dashboard/components/Surveys'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../contexts/GameContext/useGame'
import { toUrlId } from '../../utils/toUrlId'
import Surveys from '../game/dashboard/components/Surveys'

const SignupSuccess = () => {
  const { user, masterUser } = useAuth()
  const { activeGame } = useGame()
  const { t } = useTranslation()

  const parentId = masterUser?.role.parentId

  return (
    <>
      <HeadingWrapper>
        <Heading primary smallFont medium>
          {t('signUp.success.title', { firstName: user?.profile.firstName })} {'\u{1F680}'}
        </Heading>
      </HeadingWrapper>
      <Wrapper marginTop={'50px'}>
        <Surveys />
      </Wrapper>
      <Wrapper veryTall>{!user?.info.gameId && <JoinGameCTA showExplanation />}</Wrapper>
      {masterUser?.id === user?.id && (!parentId || (parentId && parentId === user?.id)) && (
        <Wrapper width="320px">
          <LinkButton to={'/signup/family/select-age'} secondary style={{ marginBottom: 10 }}>
            {t('signUp.success.newFamilyMember') || ''}
          </LinkButton>
        </Wrapper>
      )}
      <Wrapper width="320px">
        <LinkButton to={activeGame?.id && user?.info.gameId ? '/dashboard' : '/'} primary replace>
          {t('signUp.success.goToButton.goTo', {
            place:
              activeGame?.id && user?.info.gameId
                ? t('signUp.success.goToButton.dashboard')
                : t('signUp.success.goToButton.home'),
          }) || ''}
        </LinkButton>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query SignupSuccess($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default SignupSuccess
