import device from '../../styles/device'
import styled from 'styled-components'
import Wrapper from '../../components/Wrapper/Wrapper'
import { prop } from 'styled-tools'
import LinkButton from '../../components/LinkButton/LinkButton'
import { darken } from 'polished'

export const ButtonsWraper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 3rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  width: fit-content;
  gap: 20px;
`

export const TextWrapper = styled.div`
  text-align: left;
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 2.8rem;
  }
  h3 {
    font-size: 2rem;
    color: #f15a29;
  }
  p {
    text-align: left;
    &.big {
      font-size: 1.8rem;
    }
  }

  @media ${device.mobLandscape} {
    h1 {
      font-size: 4rem;
    }
    h2 {
      font-size: 3.8rem;
    }
    h3 {
      font-size: 2.2rem;
    }
    p {
      &.big {
        font-size: 2rem;
      }
    }
  }
  @media ${device.tabletLandscape} {
    h1 {
      font-size: 5rem;
    }
    h2 {
      font-size: 4.8rem;
    }
    h3 {
      font-size: 2.4rem;
    }
    p {
      font-size: 1.8rem;
      &.big {
        font-size: 2.2rem;
      }
    }
  }
  @media ${device.mobOnly} {
    h2,
    h3 {
      text-align: left;
    }
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;

  @media ${device.tabletPortrait} {
    flex-direction: row;
    }
  }
`

export const TertiaryButton = styled.button`
  background-color: transparent;
  color: ${(props) => props.theme.colors.body};
  padding: 8px 20px 10px;
  border: solid 2px ${(props) => props.theme.colors.body};
  font-size: 1.6rem;

  &:hover {
    background-color: ${(props) => props.theme.colors.orange};
    color: ${(props) => props.theme.colors.white};
    border: solid 2px ${(props) => props.theme.colors.orange};
  }

  &:focus {
    background-color: ${(props) => props.theme.colors.orange};
    color: ${(props) => props.theme.colors.white};
    border: solid 2px ${(props) => props.theme.colors.orange};
  }

  @media ${device.mobLandscape} {
    font-size: 1.8rem;
    padding: 9px 30px 11px;
  }

  @media ${device.tabletLandscape} {
    font-size: 2rem;
  }
`

export const CTAButtonExtra = styled(LinkButton)`
  width: auto;
  padding: 10px 20px 12px;
  font-size: 1.6rem;
  display: inline-block;
  background: ${(props) => props.theme.colors.orange};
  box-sizing: border-box;
  color: ${(props) => props.theme.colors.white};
  border: none;
  margin: 0;

  ${(props) => props.theme.hover} {
    background-color: ${(props) => darken(0.1, props.theme.colors.orange)};
    color: ${(props) => props.theme.colors.white};
    border: none;
  }

  @media ${device.mobLandscape} {
    font-size: 1.8rem;
    padding: 11px 32px 13px;
  }

  @media ${device.tabletLandscape} {
    font-size: 2rem;
  }
`

export const GetHelpButton = styled.button`
  display: inline-block;
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  padding: 0;
  font-weight: lighter;
  font-family: inherit;
  text-decoration: underline;

  @media ${device.tabletLandscape} {
    font-size: inherit;
    text-align: left;
  }
  &:hover {
    text-decoration: underline;
    background-color: transparent;
  }
  &:focus {
    text-decoration: underline;
    background-color: transparent;
  }
  &:active {
    outline: none;
  }
`

export const SpeakButton = styled.button`
  background-color: ${(props) => props.theme.colors.body};
  padding: 10px 20px 12px;
  font-size: 1.6rem;
  margin: 0;

  ${(props) => props.theme.hover} {
    background-color: ${(props) => props.theme.colors.body};
  }

  @media ${device.mobLandscape} {
    font-size: 1.8rem;
    padding: 11px 32px 13px;
  }

  @media ${device.tabletLandscape} {
    font-size: 2rem;
  }
`

export const ColumnWrapper = styled.div`
  width: 100%;
  @media ${device.tabletPortrait} {
    max-width: 50%;
  }
`

export const Divider = styled.div`
  height: 2px;
  margin: 6.4rem 2rem;
  background-color: ${(props) => props.theme.colors.body};
  @media ${device.tabletPortrait} {
    display: none;
  }
  @media ${device.mobLandscape} {
    margin-left: 4rem;
    margin-right: 4rem;
  }
`

export const GroupImageWrapper = styled.div`
  padding: 2rem;
  position: relative;
  @media ${device.tabletPortrait} {
    display: none;
  }
`
export const HeroWrapper = styled.div`
  h1 {
    width: 100%;
  }
  height: 370px;
  @media ${device.mobPortrait} {
    height: 300px;
  }
  @media ${device.tabletPortrait} {
    h1 {
      width: 70%;
    }

    height: 390px;
  }
`

export const WalkImageWrapper = styled.div`
  display: none;
  @media ${device.tabletPortrait} {
    display: block;
    height: 500px;
  }
  @media ${device.desktop} {
    height: 600px;
  }
`

export const WalkTextWrapper = styled.div`
  @media ${device.tabletPortrait} {
    padding-top: 6rem;
    padding-bottom: 5rem;
  }
`
export const GreyWrapper = styled.div`
  background-color: #edf0f7;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`

export const HiddenWrapper = styled(Wrapper)`
  @media ${device.tabletPortrait} {
    overflow: hidden;
  }
`

export const ContentWrapper = styled.div`
  max-width: ${(props) => props.theme.maxHeaderWidth};
  margin: 0 auto;
`
