import styled, { css } from 'styled-components'
import { darken } from 'polished'
import React from 'react'
import ContextLink from '../ContextLink'

export const LinkButtonStyles = css`
  display: block;
  margin: auto;
  text-align: center;
  user-select: none;
  width: 100%;
  max-width: 400px;
  border-radius: 6px;
  padding: 0.8rem 0.5rem 1rem 0.5rem;
  cursor: pointer;
  font-family: ${(props) => props.theme.fonts.secondaryFont};
  font-size: ${(props) => props.theme.fonts.m};

  transition: ${(props) => props.theme.transitions.transition};

  ${(props) => props.theme.hover} {
    text-decoration: none;
  }
  ${(props) => props.theme.hover} {
    text-decoration: none;
  }

  ${(props) =>
    props.primary &&
    `
        background-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.white};

        ${props.theme.hover} {
            background-color: ${darken(0.1, props.theme.colors.primary)};
        }
    `};

  ${(props) =>
    props.primarySmall &&
    `
        background-color: ${props.theme.colors.primary};
        color: ${props.theme.colors.white};
        font-family: ${props.theme.fonts.primaryFont};
        font-size: ${props.theme.fonts.ms};
        margin-top: 10px;
        margin-left: 0;
        width: 195px;
        ${props.theme.hover} {
            text-decoration: underline;
        }
    `};

  ${(props) =>
    props.secondary &&
    `
        background-color: ${props.theme.colors.secondary};
        color: ${props.theme.colors.white};
        
        ${props.theme.hover} {
            background-color: ${darken(0.1, props.theme.colors.secondary)};
        }
    `};

  ${(props) =>
    props.tertiary &&
    `
        background-color: ${props.theme.colors.white};
        color: ${props.theme.colors.secondary};
        border: 1px solid ${props.theme.colors.secondary};
        ${props.theme.hover} {
            color: ${props.theme.colors.body};
            border: 1px solid ${props.theme.colors.body};            
        }
    `};
`

export const InternalLinkButton = styled((props) => <ContextLink {...props} />)`
  ${LinkButtonStyles}
`

export const ExternalLinkButton = styled.a`
  ${LinkButtonStyles}
`
