import React, { FC } from 'react'
import useAuth from '../../contexts/AuthContext/useAuth'
import useGame from '../../contexts/GameContext/useGame'
import { Miles, Points, TABLETWIDTH, Unit } from './GameStats.styled'
import TextWithCTA from '../../components/TextWithCTA/TextWithCTA'
import { useSurvey } from '../../hooks/useSurvey/useSurvey'
import AnimateNumber from '../../components/AnimateNumber/AnimateNumber'
import LastBeatBox from '../LastBeatBox'
import SwipeableContent from '../../components/SwipeableContent'
import { StatsProps } from './index'
import { differenceInDays } from 'date-fns'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { GameStatus, Survey, usePlayerRoutesQuery } from '../../generated/api'

export const PlayerStats: FC<StatsProps> = ({ carousel, pageWidth, carouselColumns = 2 }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { data: routesRes } = usePlayerRoutesQuery({ playerId: user?.id || '' }, { enabled: Boolean(user?.id) })
  const routes = routesRes?.playerRoutes.routes

  const { game } = useGame()
  const { tellUsAboutYouSurveyEnabled, closingSurveyEnabled, surveyLink, surveys } = useSurvey()

  const addPoints = (survey: Survey | undefined) => {
    if (!survey || survey.isConfirmed) return 0
    const diff = differenceInDays(new Date(), new Date(survey.createdAt))
    if (Math.abs(diff) > 1) return 0
    return 100
  }

  const surveyElement = () => {
    if (
      (closingSurveyEnabled || tellUsAboutYouSurveyEnabled) &&
      game?.info.status !== GameStatus.Finished &&
      game?.info.status !== GameStatus.Sustain
    ) {
      return (
        <li>
          <Unit>
            <TextWithCTA
              title={
                closingSurveyEnabled
                  ? t('game.dashboard.stats.surveyElement.titleClosing')
                  : t('game.dashboard.stats.surveyElement.titleHealth')
              }
              text={
                closingSurveyEnabled
                  ? t('game.dashboard.stats.surveyElement.textClosing')
                  : t('game.dashboard.stats.surveyElement.textHealth', { name: user?.profile.firstName })
              }
              buttonText={t('game.dashboard.stats.surveyElement.buttonText') || ''}
              linkUrl={surveyLink || ''}
              external
              openInNewWindow={false}
            />
          </Unit>
        </li>
      )
    }
  }

  const content = [
    <li key="points">
      <Points>
        <AnimateNumber
          number={user?.points?.all || 0 + addPoints(surveys.tellUsAboutYou) + addPoints(surveys.closing)}
          format="0,0"
        />
        <p>{t('game.dashboard.stats.points')}</p>
      </Points>
    </li>,
    surveyElement(),
    <li key="miles">
      <Miles>
        <AnimateNumber
          number={(routes?.filter((r) => r.points).length || 0) * (game?.info.settings.locationDistance || 0)}
          format="0.0"
        />
        <p>{t('game.dashboard.stats.miles')}</p>
      </Miles>
    </li>,
    <li key="lastbox">
      <Unit>
        <LastBeatBox />
      </Unit>
    </li>,
  ]

  return (
    <ul>
      {carousel && pageWidth && pageWidth < TABLETWIDTH ? (
        <SwipeableContent
          isScrollable={false}
          mobileOnly
          pages={content
            .reduce((resultArray: any[], item, index) => {
              const chunkIndex = Math.floor(index / carouselColumns)

              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = [] // start a new chunk
              }

              resultArray[chunkIndex].push(item)

              return resultArray
            }, [])
            .map((c) => c.flat())}
        />
      ) : (
        content
      )}
    </ul>
  )
}
