import React from 'react'
import { useSpring, animated } from 'react-spring'

import Checkbox from '../../../../../components/Checkbox/Checkbox'
import ArrowIcon from '../../../../../assets/images/svg/ic_play_arrow.svg'
import { FilterBoxWrapper, Themes, Toggle } from './FilterBox.styled'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { isAppleMapsSupported } from '../isAppleMapsSupported'
import useSsrLocalStorage from '../../../../../utils/useSsrLocalStorage'
import { LocationTypes } from '../../../../../generated/api'
import { useMapState } from '../Context'
import useLocations from '../useLocations'

const FilterBox = () => {
  const { width } = useWindowSize()
  const { t } = useTranslation()
  const { isExpanded, setIsExpanded, setFilter, filter, userHasApp } = useMapState()
  const { geoJsonData } = useLocations()
  const [useGoogle, setUseGoogle] = useSsrLocalStorage(
    'useGoogleMapsForDirections',
    isAppleMapsSupported() ? 'false' : 'true',
  )
  const filterBoxVisibility = {
    outdoorGyms: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.OutdoorGym),
    schools: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.School),
    distributionPoints: geoJsonData?.features?.find(
      ({ properties }) => properties.type === LocationTypes.DistributionPoint,
    ),
    beatBoxes: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.BeatBox),
    gems: geoJsonData?.features?.find(({ properties }) => properties.type === LocationTypes.Gem) && userHasApp,
  }
  const props = useSpring({
    width: isExpanded ? (width < 360 ? '210px' : '250px') : '5px',
  })

  const handleClick = (event) => setFilter((prev) => ({ ...prev, [event.target.name]: event.target.checked }))

  return (
    <FilterBoxWrapper isExpanded={isExpanded}>
      <div className="inner">
        <animated.div className="mask" style={props}>
          <div>
            <fieldset>
              <legend>{t('game.map.filterBox.title')}</legend>
              {filterBoxVisibility.beatBoxes && (
                <Checkbox
                  text={t('game.map.filterBox.beatBoxes') || ''}
                  smallFont
                  customFill={Themes.beatBox}
                  value={filter.beatBoxes}
                  id="beat-boxes"
                  onChange={handleClick}
                  name={'beatBoxes'}
                />
              )}
              {filterBoxVisibility.distributionPoints && (
                <Checkbox
                  text={t('game.map.filterBox.distributionPoints') || ''}
                  smallFont
                  customFill={Themes.distributionPoint}
                  value={filter.distributionPoints}
                  id="distribution-points"
                  onChange={handleClick}
                  name={'distributionPoints'}
                />
              )}
              {filterBoxVisibility.outdoorGyms && (
                <Checkbox
                  text={t('game.map.filterBox.outdoorGyms') || ''}
                  smallFont
                  customFill={Themes.outdoorGym}
                  id="outdoor-gyms"
                  value={filter.outdoorGyms}
                  onChange={handleClick}
                  name={'outdoorGyms'}
                />
              )}
              {filterBoxVisibility.schools && (
                <Checkbox
                  text={t('game.map.filterBox.schools') || ''}
                  smallFont
                  customFill={Themes.school}
                  id="schools"
                  value={filter.schools}
                  onChange={handleClick}
                  name={'schools'}
                />
              )}
              {filterBoxVisibility.gems && (
                <Checkbox
                  text={t('game.map.filterBox.gems') || ''}
                  smallFont
                  customFill={Themes.gem}
                  value={filter.gems}
                  id="gems"
                  onChange={handleClick}
                  name="gems"
                />
              )}
            </fieldset>
            {isAppleMapsSupported() && (
              <Checkbox
                className="unstyled"
                text={t('game.map.filterBox.googleDirections') || ''}
                id="google"
                value={useGoogle === 'true'}
                isStyled={false}
                onChange={(ev) => setUseGoogle(ev.target.checked.toString())}
                name={'google'}
              />
            )}
          </div>
        </animated.div>

        <Toggle onClick={() => setIsExpanded(!isExpanded)} isExpanded={isExpanded}>
          <ArrowIcon />
        </Toggle>
      </div>
    </FilterBoxWrapper>
  )
}

export default FilterBox
