import React, { FC } from 'react'
import LinkButton from '../components/LinkButton/LinkButton'
import Wrapper from '../components/Wrapper/Wrapper'
import SEO from '../components/seo'
import GlobalStyle from '../styles/global'
import CookieConsent from '../components/CookieConsent/CookieConsent'
import { MainContainer, OuterContainer } from '../contexts/Layout/Layout.styled'
import Header from '../components/Header/Header'
import BtsFox from '../assets/icons/BtsFox'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const NotFoundPage: FC = () => {
  const { t } = useTranslation()

  const browser = typeof window !== 'undefined' && window

  return browser ? (
    <>
      <GlobalStyle />
      <CookieConsent />
      <OuterContainer style={{ marginTop: '50px' }}>
        <Header force />
        <MainContainer positiveMargin>
          <SEO title="404: Not found" />
          <Wrapper>
            <h1>{t('404.title')}</h1>
            <p>{t('404.textFound')}</p>
            <p>{t('404.textNotFound')}</p>
            <BtsFox width={240} height={240} />
          </Wrapper>
          <Wrapper veryTall>
            <LinkButton to="/" tertiary>
              <span>{t('404.buttonText')}</span>
            </LinkButton>
          </Wrapper>
        </MainContainer>
      </OuterContainer>
    </>
  ) : null
}

export const query = graphql`
  query ($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default NotFoundPage
