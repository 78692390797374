import React, { useEffect, useState } from 'react'
import { CookieConsentBanner } from '@porscheofficial/cookie-consent-banner-react'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGoogleTagManager from '../../hooks/useGoogleTagManager'

const CookieConsent = () => {
  const [consents, setConsents] = useState<string[]>([])
  const { pushEvent } = useGoogleTagManager()
  const { t } = useTranslation()

  useEffect(() => {
    if (typeof window === undefined) return
    if (localStorage.getItem('consentMode') === null) {
      window.dataLayer.push('consent', 'default', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        personalization_storage: 'denied',
        functionality_storage: 'denied',
        security_storage: 'denied',
      })
    } else {
      window.dataLayer.push('consent', 'default', JSON.parse(localStorage.getItem('consentMode') ?? ''))
    }
  }, [])

  useEffect(() => {
    if (typeof window === undefined) return

    if (consents.includes('analytics')) {
      pushEvent({
        event: 'analytics',
      })
      if (process.env.GATSBY_ENV === 'production') {
        window.clarity('consent')
      }
    }

    if (consents.includes('marketing')) {
      pushEvent({
        event: 'marketing',
      })
    }

    if (consents.includes('technically_required')) {
      pushEvent({
        event: 'technically_required',
      })
    }

    const consentMode = {
      functionality_storage: consents.includes('technically_required') ? 'granted' : 'denied',
      security_storage: consents.includes('technically_required') ? 'granted' : 'denied',
      ad_storage: consents.includes('marketing') ? 'granted' : 'denied',
      analytics_storage: consents.includes('analytics') ? 'granted' : 'denied',
      personalization: consents.includes('marketing') ? 'granted' : 'denied',
    }
    localStorage.setItem('consentMode', JSON.stringify(consentMode))

    window.dataLayer.push('consent', 'update', {
      necessary: consents.includes('technically_required'),
      analytics: consents.includes('analytics'),
      preferences: consents.includes('marketing'),
      marketing: consents.includes('marketing'),
    })
  }, [consents, pushEvent])

  if (typeof window === 'undefined') return null

  return (
    <>
      <CookieConsentBanner
        id={'cookieBanner'}
        handlePreferencesUpdated={(event) => {
          setConsents(event.acceptedCategories)
        }}
        handlePreferencesRestored={(event) => {
          setConsents(event.acceptedCategories)
        }}
        btnLabelAcceptAndContinue={t('components.cookieConsent.button.allowAll') || ''}
        btnLabelSelectAllAndContinue={t('components.cookieConsent.button.acceptAll') || ''}
        btnLabelOnlyEssentialAndContinue={t('components.cookieConsent.button.necessary') || ''}
        btnLabelPersistSelectionAndContinue={t('components.cookieConsent.button.confirm') || ''}
        availableCategories={[
          {
            description: t('components.cookieConsent.necessary'),
            key: 'technically_required',
            label: 'Necessary cookies',
            isMandatory: true,
          },
          {
            description: t('components.cookieConsent.analytics'),
            key: 'analytics',
            label: 'Cookies that measure website use',
          },
        ]}
      >
        <h2 style={{ color: 'white' }}>{t('components.cookieConsent.title')}</h2>
        <p>
          {t('components.cookieConsent.text1')} <Link to={'/cookies'}>{t('components.cookieConsent.policy')}</Link>.{' '}
          {t('components.cookieConsent.text2')}
          <button
            style={{ backgroundColor: 'transparent' }}
            onClick={() => document.dispatchEvent(new Event('cookie_consent_details_show'))}
            type="button"
          >
            {t('components.cookieConsent.more')}
          </button>
        </p>
      </CookieConsentBanner>
    </>
  )
}

export default CookieConsent
