import React, { FC } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../../components/seo'
import CardGrid, { Post } from '../../../components/CardGrid/CardGrid'
import ShallowArc from '../../../components/ShallowArc/ShallowArc'
import { Heading } from '../../../components/Heading/Heading.styled'
import { BodyEllipsis } from '../../../utils/ellipsis'
import { MainHeading, SubHeading, Wrapper } from './activities.styled'
import RichText from '../../../components/RichText'
//import { IGame } from '../../../interfaces/game.interface'
//import { NodeSanityActivity, SanityActivity, SanityContent } from '../../../interfaces/activity.interface'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'

const ActivitiesPage: FC<IPageProps<Queries.ActivitiesPageQuery>> = ({ data, pageContext }) => {
  const { game } = pageContext
  const { t } = useTranslation()
  const scheme = data.sanityGame
  const content = data.sanityContent
  const activities = data.allSanityActivity.edges
    ?.map(({ node }) => node)
    .filter((node) => {
      if (scheme?.displayAll) return true
      return scheme?.activities?.map((a) => a?.id).includes(node.id)
    })

  return (
    <>
      <SEO title={t('game.activities.seoTitle', { schemeName: scheme?.name })} />
      <MainHeading>{content?.title}</MainHeading>
      <SubHeading>{BodyEllipsis(<RichText blocks={content?.description} />)}</SubHeading>

      <Wrapper backgroundColor="rgb(53, 152, 170)">
        <ShallowArc backgroundColor="rgb(53, 152, 170)" />
        {activities && activities?.length > 0 ? (
          <div>
            <CardGrid context={{ game }} posts={activities} />
          </div>
        ) : (
          <Heading as="h2" style={{ color: 'white' }}>
            {t('game.activities.noActivities')}
          </Heading>
        )}
      </Wrapper>
    </>
  )
}

export default ActivitiesPage

export const query = graphql`
  query ActivitiesPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      ...gameDetails
      displayAll
      activities {
        id
      }
    }

    allSanityActivity(filter: { i18n_lang: { eq: $language } }) {
      edges {
        node {
          id
          title
          description: _rawDescription
          file {
            asset {
              url
              size
              extension
            }
          }
          image {
            asset {
              gatsbyImageData
            }
          }
          alt
        }
      }
    }

    sanityContent(internalTitle: { eq: "Activities Intro" }, i18n_lang: { eq: $language }) {
      title
      description: _rawBody(resolveReferences: { maxDepth: 15 })
    }
  }
`
