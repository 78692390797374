import React, { useEffect, useState } from 'react'
import FamilyMember from '../../components/FamilyMember'
import styled from 'styled-components'
import useAuth from '../../contexts/AuthContext/useAuth'
import theme from '../../styles/theme'
import { useFamilySurveyActive } from './useFamilySurveyActive'
import AddFamilyMemberButton from '../../templates/manageAccount/components/addFamilyMemberButton'
import useGame from '../../contexts/GameContext/useGame'
import { GameStatus, useFindFamilyPlayersQuery, useGetActiveGameQuery } from '../../generated/api'
import useGameRestricted from '../../hooks/useGameRestricted'

const MemberContainer = styled.button`
  display: flex;
  position: relative;
  background-color: transparent;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }
`

const UsersContainer = styled.div`
  display: flex;
  overflow-x: auto;
`

const MemberName = styled.p`
  font-size: 1.4rem;
  margin-top: 5px;
  color: ${({ isActive }) => (isActive ? 'black' : theme.colors.midDarkGrey)};
`

const Dot = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${theme.colors.warning};
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 7px;
  right: 10px;
  z-index: 10;
`

const FamilyMemberSelect = () => {
  const { user, setUser, masterUser, family: familyData, refetch } = useAuth()
  const [family, setFamily] = useState(familyData)
  const { isGameRestricted } = useGameRestricted()

  const { data } = useFindFamilyPlayersQuery({ input: { parentId: masterUser?.role.parentId || '' } })
  const res = JSON.stringify(data)

  useEffect(() => {
    const userInFamily = data?.findFamilyPlayers.find((player) => player.id === user?.id)
    if (!userInFamily) {
      refetch()
    }
    if (data) setFamily(data?.findFamilyPlayers)
  }, [res])

  const familyWithSurvey = useFamilySurveyActive()
  const { game } = useGame()
  const isParent = masterUser?.id === masterUser?.role.parentId

  if (!masterUser?.id) return null
  return (
    <>
      <UsersContainer>
        <MemberContainer onClick={() => setUser(masterUser.id)} key={masterUser.id}>
          {familyWithSurvey.includes(masterUser.id) && game?.info.status !== GameStatus.Finished && <Dot />}
          <FamilyMember isActive={user?.id === masterUser.id} />
          <MemberName isActive={user?.id === masterUser.id}>
            {masterUser.profile.firstName || masterUser.info.publicName}
          </MemberName>
        </MemberContainer>
        {family
          ?.filter((user) => user?.id !== masterUser.id)
          .map((member) => {
            return (
              <MemberContainer onClick={() => setUser(member.id)} key={member.id}>
                {familyWithSurvey.includes(member.id) && game?.info.status !== GameStatus.Finished && <Dot />}
                <FamilyMember isActive={user?.id === member.id} />
                <MemberName isActive={user?.id === member.id}>
                  {member.profile.firstName || member.info.publicName}
                </MemberName>
              </MemberContainer>
            )
          })}
      </UsersContainer>
      {isParent && !isGameRestricted && <AddFamilyMemberButton />}
    </>
  )
}

export default FamilyMemberSelect
