import React, { FC } from 'react'
import RowItem from '../RowItem/RowItem'
import { InvertedMask, LeaderboardWrapper, Mask } from './LeaderboardTable.styled'
import useAuth from '../../contexts/AuthContext/useAuth'
//import { Leaderboard } from '../../API'
import { LeaderboardType } from '../../containers/Leaderboards/interfaces'
import round from 'lodash/round'
import { indexOf } from 'lodash'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  LeaderboardPointType,
  LeaderboardsResults,
  LeaderboardsTeamResults,
  LeaderboardTeamPointType,
} from '../../generated/api'

interface LeaderboardProps {
  data: Partial<LeaderboardsTeamResults>[]
  pointsType: LeaderboardTeamPointType | LeaderboardPointType
  highlight?: (data) => unknown
  mini?: boolean
  type?: LeaderboardType
  total: number
  yourPosition: number
  topIndex: number
  bottomIndex: number
}

export interface LeaderboardElement extends LeaderboardsTeamResults {
  all?: number
}

const LeaderboardTable: FC<LeaderboardProps> = ({
  data,
  pointsType,
  mini,
  type,
  total,
  yourPosition,
  topIndex,
  bottomIndex,
}) => {
  const { user, isAuthenticated } = useAuth()
  const { t } = useTranslation()

  const selectPoints = (element: Partial<LeaderboardElement>): number => {
    if (!element.amount) return 0
    return element.amount
  }

  return (
    <>
      <LeaderboardWrapper>
        {!mini && topIndex !== 0 && <InvertedMask />}
        {data?.slice(topIndex, bottomIndex)?.map((element: Partial<LeaderboardElement>) => (
          <RowItem
            mini={mini}
            key={element.id}
            highlight={
              isAuthenticated
                ? type === LeaderboardType.player
                  ? element?.id === user?.id
                  : element?.id === user?.info.teamId
                : false
            }
            isCurrentUser={element?.id === user?.info.teamId || element?.id === user?.id}
            rank={element?.position || 0}
            name={element?.name || ''}
            teamMemberCount={element?.totalMembers}
            points={selectPoints(element)}
          />
        ))}
        {!mini && bottomIndex < total && <Mask />}

        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          transition={Slide}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          bodyStyle={{
            fontSize: '1.8rem',
            fontFamily: 'co-text',
          }}
          style={{
            width: '450px',
          }}
        />
      </LeaderboardWrapper>
    </>
  )
}

export default LeaderboardTable
