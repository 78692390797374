import React, { FC, useState } from 'react'
import { ErrorMessage, Formik } from 'formik'
import * as Yup from 'yup'
import Input from '../../../../components/Input/Input'
import { Error, FormItem, FormItemGrid } from '../../../../components/FormElements/FormElements.styled'
import Button from '../../../../components/Button/Button'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { getChangeEmailSchema } from './validation'
import { useManagePlayers } from '../../../signup/hooks/useManagePlayers'

const HandleEmailForm = ({ toastmessage }) => {
  const { user } = useAuth()
  const { t } = useTranslation()
  const { profile } = user || {}
  const changeEmailSchema = getChangeEmailSchema(t)
  const { onUpdatePlayer } = useManagePlayers()
  const [email, setEmail] = useState<string>(user?.profile.email || '')

  return (
    <Formik
      enableReinitialize
      initialValues={{
        verificationCode: null,
        email: profile?.email || '',
        submit: null,
      }}
      validationSchema={changeEmailSchema}
      onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }): Promise<void> => {
        try {
          if (user?.id) {
            await onUpdatePlayer({
              id: user?.id,
              profile: {
                email: values.email,
              },
            })
          }
          resetForm()
          setStatus({ success: true })
          setSubmitting(false)
          toastmessage()
          setEmail(values.email)
        } catch (error) {
          console.error(error)
          setStatus({ success: false })
          if (error?.errors?.[0].message.includes('An account with the given email already exists.')) {
            setErrors({ submit: t('errors.cognito.emailChange.emailExist') || undefined })
          } else {
            setErrors({ submit: t('errors.generic') || undefined })
          }
          setSubmitting(false)
        }
      }}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, isSubmitting, handleChange }) => (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <FormItem>
              <Input
                id="email"
                type="email"
                autoComplete="email"
                text={t('manageAccount.emailChange.emailInputLabel') || ''}
                value={values.email}
                hasError={Boolean(touched.email && errors.email)}
                error={touched.email ? errors.email : undefined}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {/*TODO email change confirm email and what to display here*/}
              {/*<span>{t('manageAccount.emailChange.info', { email: email })}</span>*/}
            </FormItem>
            <ErrorMessage name="submit" component={(props) => <Error className="checkboxError" {...props} />} />
            <FormItem>
              <Button
                text={t('manageAccount.emailChange.buttonText')}
                secondary
                onClick={handleSubmit}
                isSubmitting={isSubmitting}
                disabled={values?.email === user?.profile.email || isSubmitting}
                type="submit"
              />
            </FormItem>
          </form>
        </>
      )}
    </Formik>
  )
}

export default HandleEmailForm
