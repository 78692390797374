import useAuth from '../../contexts/AuthContext/useAuth'
import { JoinGameInput, useJoinGameMutation } from '../../generated/api'
import { useQueryClient } from '@tanstack/react-query'
const useJoinGame = () => {
  const { user } = useAuth()
  const queryClient = useQueryClient()
  const { mutateAsync: join } = useJoinGameMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['GetPlayerById'] }),
  })

  return async (values: JoinGameInput) => {
    if (!values.gameId) return
    await join({
      input: {
        gameId: values.gameId,
        consentText: values.consentText,
      },
    })
  }
}

export default useJoinGame
