import React from 'react'
import styled from 'styled-components'
import theme from '../../styles/theme'
import SVGImage from '../DynamicImg/SVGImage'
import device from '../../styles/device'
import RichText from '../RichText'
import useGame from '../../contexts/GameContext/useGame'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Wrapper = styled.div`
  width: 350px;
  height: auto;

  @media ${device.tabletPortrait} {
    width: 600px;
    height: auto;
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid ${theme.colors.grey};
  display: flex;
`

const Text = styled.p`
  color: ${theme.colors.body};
  text-align: left;

  font-size: 1.3rem;

  @media ${device.tabletPortrait} {
    font-size: 1.6rem;
  }
`

const UpdateMessage = () => {
  const { game } = useGame()
  const { t } = useTranslation()
  return (
    <Wrapper style={{ marginBottom: '30px' }}>
      <Content>
        <SVGImage
          alt={t('aria.alt.handBagWoman') || ''}
          filename={'handbag_woman'}
          svgProperties={{ width: 70, height: 180 }}
        />
        <div
          style={{
            width: '80%',
            height: 'auto',
            marginLeft: 20,
            paddingTop: 20,
            paddingBottom: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            textAlign: 'left',
          }}
        >
          <h1>{game?.content?.maintenanceTitle || t('components.updateMessage.titleAlt')}</h1>
          {game?.content?.maintenanceDescription && (
            <RichText blocks={game.content.maintenanceDescription} context={{ game }} />
          )}
        </div>
      </Content>
    </Wrapper>
  )
}

export default UpdateMessage
