import React, { FC, useState } from 'react'
import { graphql } from 'gatsby'
import SEO from '../../components/seo'
import ShallowArc from '../../components/ShallowArc/ShallowArc'
import SVGImage from '../../components/DynamicImg/SVGImage'
import {
  Container,
  MainHeading,
  StyledCardContainerShop,
  StyledCardShop,
  StyledImg,
  SubHeading,
  Wrapper,
  WrapperContainer,
} from './shop.styled'
import { BodyEllipsis } from '../../utils/ellipsis'
import RichText from '../../components/RichText'
import theme from '../../styles/theme'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../contexts/PageContext'

const GATSBY_SITE_URL = process.env.GATSBY_SITE_URL

const handlePrice = (quantity: number, price: number): number => {
  if (quantity <= 0) return 0
  if (!quantity) return price
  return quantity * price
}

const handleQuantity = (quantity: number): number => (!quantity || quantity <= 0 ? 1 : 0)

const ShopPage: FC<IPageProps<Queries.ShopPageQuery>> = ({ data }) => {
  const { t } = useTranslation()
  const [productQuantity, setProductQuantity] = useState({})

  const content = data?.sanityContent
  const setProductType = content?.description as Record<string, any> | null | undefined
  const products = setProductType?.find((el) => el._type === 'productGrid')

  return (
    <>
      <SEO title={t('shop.title')} />
      <MainHeading>{content?.title || t('shop.title')}</MainHeading>

      <SubHeading>{BodyEllipsis(<RichText blocks={content?.description} />)}</SubHeading>

      <Wrapper backgroundColor="rgb(53, 152, 170)">
        <div style={{ position: 'absolute', right: '7%', top: '-1%' }}>
          <SVGImage filename="beattie.svg" svgProperties={{ width: '90px', height: '130px' }} />
        </div>
        <ShallowArc backgroundColor="rgb(53, 152, 170)" />
        {products?.items?.map((product) => {
          return (
            <WrapperContainer id={product?.name}>
              <StyledCardShop gridSize={1}>
                <Container>
                  <StyledImg
                    style={{ objectFit: 'scale-down' }}
                    src={product.image?.asset?.url}
                    alt={product.alt || t('aria.alt.productImage')}
                  />
                  <StyledCardContainerShop>
                    <div>
                      <h2>{product?.name}</h2>
                      <p>{product?.description}</p>
                    </div>
                    <div
                      style={{
                        paddingBottom: 10,
                        paddingTop: 10,
                        display: 'flex',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <label htmlFor={product.id}>{t('shop.quantity')}</label>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <input
                            style={{
                              width: 80,
                              fontSize: '1.5rem',
                            }}
                            type="number"
                            name="quantity"
                            min={1}
                            max={999}
                            maxLength={3}
                            defaultValue={1}
                            id={product.id}
                            onChange={(e) => {
                              if (Number(e.target.value) > 999 || Number(e.target.value) < 0) return
                              return setProductQuantity({
                                ...productQuantity,
                                [product?.productID]: Number(e.target.value),
                              })
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                marginLeft: 40,
                                fontWeight: 'bold',
                              }}
                            >
                              &#163;
                              {handlePrice(productQuantity?.[product?.productID], product?.price)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {productQuantity?.[product?.productID] < 1 ? (
                      <button
                        style={{
                          backgroundColor: theme.colors.orange,
                          maxWidth: 160,
                        }}
                        disabled={true}
                      >
                        <p style={{ margin: 0 }}>{t('shop.addToCard')}</p>
                      </button>
                    ) : (
                      <button
                        disabled={productQuantity?.[product?.productID] < 1}
                        style={{
                          backgroundColor: theme.colors.orange,
                          maxWidth: 160,
                        }}
                        className="snipcart-add-item"
                        data-item-id={product.productID}
                        data-item-price={product.price.toFixed(2)}
                        data-item-weight={product.weight}
                        data-item-quantity={handleQuantity(productQuantity?.[product?.productID])}
                        data-item-url={`${GATSBY_SITE_URL}/shop/`}
                        data-item-description={product?.description}
                        data-item-image={product?.image?.asset?.url}
                        data-item-name={product?.name}
                      >
                        <p style={{ margin: 0 }}>{t('shop.addToCard')}</p>
                      </button>
                    )}
                  </StyledCardContainerShop>
                </Container>
              </StyledCardShop>
            </WrapperContainer>
          )
        })}
      </Wrapper>
    </>
  )
}

export default ShopPage

export const query = graphql`
  query ShopPage($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    sanityContent(internalTitle: { eq: "Shop" }, i18n_lang: { eq: $language }) {
      title
      description: _rawBody(resolveReferences: { maxDepth: 15 })
    }
  }
`
