import React, { createContext, FC, PropsWithChildren, RefObject, useContext, useRef, useState } from 'react'
import { MapRef } from 'react-map-gl'
import useMapControl from './useMapControl'
import { ViewState } from 'react-map-gl/src/types/external'
import { Maybe, TagTypes } from '../../../../generated/api'
import { CollectionFeature } from './useLocations'
import useAuth from '../../../../contexts/AuthContext/useAuth'

const emptyFunc = (args: any) => ({})

export interface IPopup {
  feature?: Maybe<CollectionFeature>
  isVisible?: boolean
  type?: Maybe<string>
}

interface IMapContext {
  mapRef: RefObject<MapRef>
  setIsMapLoaded: React.Dispatch<React.SetStateAction<boolean>>
  isMapLoaded: boolean
  filter: { [key: string]: boolean }
  setFilter: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>
  popup: IPopup
  setPopup: React.Dispatch<React.SetStateAction<IPopup>>
  viewState?: ViewState
  setViewState: React.Dispatch<React.SetStateAction<ViewState>>
  searchValue: string
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  isExpanded: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  tempPointCoords?: { lat: number; lng: number }
  setTempPointCoords: React.Dispatch<React.SetStateAction<{ lat: number; lng: number } | undefined>>
  userHasApp?: boolean
}

const initState: IMapContext = {
  mapRef: { current: null },
  setIsMapLoaded: emptyFunc,
  isMapLoaded: false,
  filter: {},
  setFilter: emptyFunc,
  popup: {},
  setPopup: emptyFunc,
  viewState: undefined,
  setViewState: emptyFunc,
  searchValue: '',
  setSearchValue: emptyFunc,
  isExpanded: false,
  setIsExpanded: emptyFunc,
  setTempPointCoords: emptyFunc,
}

const MapContext = createContext(initState)

const MapProvider: FC<PropsWithChildren> = ({ children }) => {
  const { masterUser } = useAuth()
  const mapRef = useRef<MapRef>(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  useMapControl({ mapRef, isMapLoaded })
  const userHasApp = !!masterUser?.tags?.find((tag) => tag.tagType === TagTypes.App)
  const [filter, setFilter] = useState({
    beatBoxes: true,
    distributionPoints: true,
    outdoorGyms: true,
    schools: true,
    gems: userHasApp,
  })

  const [popup, setPopup] = useState<IPopup>({
    feature: null,
    type: null,
    isVisible: false,
  })

  const [viewState, setViewState] = useState<ViewState>()
  const [searchValue, setSearchValue] = useState('')
  const [isExpanded, setIsExpanded] = useState(true)
  const [tempPointCoords, setTempPointCoords] = useState<{ lat: number; lng: number } | undefined>()

  return (
    <MapContext.Provider
      value={{
        mapRef,
        setIsMapLoaded,
        isMapLoaded,
        filter,
        setFilter,
        popup,
        setPopup,
        viewState,
        setViewState,
        searchValue,
        setSearchValue,
        isExpanded,
        setIsExpanded,
        tempPointCoords,
        setTempPointCoords,
        userHasApp,
      }}
    >
      {children}
    </MapContext.Provider>
  )
}

export const useMapState = () => useContext(MapContext)

export default MapProvider
