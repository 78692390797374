import styled from 'styled-components'
import device from '../../styles/device'
import { ifProp, prop, withProp } from 'styled-tools'

export const StyledCallToAction = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.midLightGrey};
  color: ${(props) => props.theme.colors.body};
  border-radius: 6px;
  max-width: 280px;
  display: flex;
  margin-bottom: 1rem;

  ${(props) =>
    props.secondary &&
    `
        background-color: ${props.theme.colors.midAqua};
        border-color: ${props.theme.colors.midAqua};
    `}

  h2 {
    margin-bottom: 0.5rem;
    line-height: 1.2;

    + button {
      margin-top: 0.5rem;
    }
  }

  button {
    width: 100%;
  }
`

export const Inner = styled.div`
  padding: 1rem;
  flex: 1;
`

export const GroupedCTAs = styled.div`
  @media ${device.mobLandscape} {
    display: flex;

    ${StyledCallToAction} {
      margin-bottom: 0;

      &:first-child {
        margin-right: 1rem;
      }
    }
  }
`

export const CallToActionWrapper = styled.div`
  display: block;
  overflow: hidden;
  * &:hover {
    text-decoration: none;
  }
  text-align: left;
  background: ${prop('bg')};
  ${withProp('border', (border) => (border ? `border:${border}` : ''))};
  border-radius: 5px;
  position: relative;
  min-height: ${withProp('isButton', (isButton) => (isButton ? '160px' : '220px'))};
  padding: ${withProp('isButton', (isButton) => (isButton ? '20px 15px 10px 95px' : '30px 15px 15px 95px'))};
  margin: 0 auto;
  width: 320px;
  box-sizing: border-box;
  > div {
    min-height: ${withProp('isButton', (isButton) => (isButton ? '70px' : '134px'))};
    display: flex;
    flex-direction: column;
    .content {
      flex: 1 1 auto;
    }
  }
  h2 {
    color: ${prop('textColor')};
    z-index: 1;
    position: relative;
    font-size: 2.6rem;
    line-height: 3.1rem;
    margin-bottom: 8px;
  }
  p {
    font-size: 1.8rem;
    color: ${prop('textColor')};
  }
  svg {
    position: absolute;
    ${withProp(
      'svgprops',
      ({ top, left, height }) => `
            top: ${top};
            left: ${left};
            height: ${height};
        `,
    )}
    z-index:0;
  }

  @media (max-width: 360px) {
    width: 100%;

    a {
      width: 100%;
    }
  }

  ${ifProp(
    { size: 'letterbox' },
    `
        @media ${device.tabletLandscape} {
            width: 665px;
            min-height: auto;
            > div {
                min-height: auto;
                flex-direction: row;
                .content {
                    flex: 1 1 auto;
                }
            }
            svg {
                top: 11px;
            }
            a {
                margin: auto;
            }

        }
    `,
  )}

  ${ifProp({ icon: 'letterbox_path' }, `overflow: visible;`)}
`
