import * as React from 'react'
import { FC, useState } from 'react'
import { graphql } from 'gatsby'
import { LockedAward } from '../../../components/Award/Locked'
import { NewInTown } from '../../../components/Award/NewInTown'
import AwardModal from '../../../components/Award/Modal'
import Content from '../../../components/Award/Modal/Content'
import Award from '../../../components/Award/Award'
import PrivateRoute from '../../../containers/PrivateRoute'
import { AwardWrapper, FlexWrapper } from '../../../components/Award/Award.styles'
import SEO from '../../../components/seo'
import { useGetScore } from '../../../hooks/useGetScore'
import Loading from '../../../components/Loading'
import { AwardSubtitle, SubHeading, MainHeading, Wrapper, Title, MainSubTitle, SubTitle } from './awards.styled'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IPageProps } from '../../../contexts/PageContext'
import { AwardType, IAward } from '../../../components/Award/Award.interface'
import { GameStatus } from '../../../generated/api'

const AwardsPage: FC<IPageProps<Queries.AwardsPageQuery>> = ({ data }) => {
  const game = data?.sanityGame
  const { t } = useTranslation()

  const { pointsDuringSpecifiedTime, points, isLoading } = useGetScore()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedAward, setSelectedAward] = useState<string | null>(null)

  const awardsMapped = data?.allSanityAwards.edges.map(({ node }) => node)
  const awardsFiltered = awardsMapped.filter((award) => typeof award.range === 'number')
  const awards = awardsFiltered.sort((a, b) => (a.range || 0) - (b.range || 0)) as unknown as IAward[]

  const pointsAwards = awards.filter((award) => award.type === AwardType.points)
  const greenCommuterAwards = awards.filter((award) => award.type === AwardType.swipes)

  const generalAwards = awards
    ?.map((award) => {
      if (award.range && award.range <= Number(points) && award.type === AwardType.points) {
        return award
      }
    })
    .filter((element) => element)

  const lastAchievedPoint = generalAwards.sort((a, b) => (a?.range || 0) - (b?.range || 0))[generalAwards.length - 1]

  const awardsSelected = awards.filter(({ name }) => name === selectedAward)[0]

  return (
    <>
      <SEO title={t('game.awards.seoTitle', { gameName: game?.name })} />

      <MainHeading>{t('game.awards.title')}</MainHeading>
      <SubHeading>{t('game.awards.subtitle')}</SubHeading>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Wrapper>
            {points < 200 ? (
              <>
                <NewInTown />
                <Title>{t('game.awards.newUser')}</Title>
              </>
            ) : (
              <FlexWrapper>
                <Award
                  icon={false}
                  customStyle={{
                    height: '17vh',
                    width: '17vh',
                  }}
                  award={lastAchievedPoint}
                />
                <AwardSubtitle
                  style={{
                    fontSize: 26,
                    fontWeight: 'bold',
                  }}
                >
                  {lastAchievedPoint?.name}
                </AwardSubtitle>
              </FlexWrapper>
            )}
            {points >= 200 && <SubTitle>{t('game.awards.reachedPoints', { points: points })}</SubTitle>}
          </Wrapper>
          <section style={{ paddingTop: 40 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {pointsAwards.map((award) => {
                if (Number(points) >= award.range) {
                  return (
                    <AwardWrapper>
                      <Award
                        onClick={() => {
                          setIsModalOpen(true)
                          setSelectedAward(award.name)
                        }}
                        award={award}
                      />
                      <MainSubTitle>{award?.name}</MainSubTitle>
                    </AwardWrapper>
                  )
                }

                return (
                  <AwardWrapper>
                    <LockedAward
                      award={award}
                      onClick={() => {
                        setIsModalOpen(true)
                        setSelectedAward(award.name)
                      }}
                    />
                    <MainSubTitle>{award?.name}</MainSubTitle>
                  </AwardWrapper>
                )
              })}
            </div>
          </section>
          <section style={{ paddingTop: 10 }}>
            <SubHeading>{t('game.awards.greenAwardTitle')}</SubHeading>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              {greenCommuterAwards.map((award) => {
                if (pointsDuringSpecifiedTime >= award.range) {
                  return (
                    <AwardWrapper>
                      <Award
                        onClick={() => {
                          setIsModalOpen(true)
                          setSelectedAward(award.name)
                        }}
                        award={award}
                      />
                      <MainSubTitle>{award?.name}</MainSubTitle>
                    </AwardWrapper>
                  )
                }
                return (
                  <AwardWrapper>
                    <LockedAward
                      award={award}
                      onClick={() => {
                        setIsModalOpen(true)
                        setSelectedAward(award.name)
                      }}
                    />
                    <MainSubTitle>{award?.name}</MainSubTitle>
                  </AwardWrapper>
                )
              })}
            </div>
          </section>
          {game?.status !== GameStatus.Finished && (
            <section
              style={{
                paddingTop: 30,
                paddingBottom: 45,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <a href={`/${game?.slug}/faqs/#q-what-are-awards`}>{t('game.awards.more')}</a>
            </section>
          )}
        </>
      )}
      <AwardModal modalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} heading={''} ariaLabel={'modal'}>
        <Content award={awardsSelected} userPoints={points} swipePoints={pointsDuringSpecifiedTime} />
      </AwardModal>
    </>
  )
}
export default (props) => <PrivateRoute component={AwardsPage} {...props} />
export const query = graphql`
  query AwardsPage($gameSlug: String, $language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
    sanityGame(slug: { eq: $gameSlug }, i18n_lang: { eq: $language }) {
      awards
      ...gameDetails
    }
    allSanityAwards(filter: { i18n_lang: { eq: $language } }) {
      edges {
        node {
          name
          description
          range
          type
          motivator
          icon {
            asset {
              url
            }
          }
          alt
          iconBackground {
            rgb {
              r
              g
              b
              a
            }
          }
        }
      }
    }
  }
`
