import React, { FC } from 'react'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import SEO from '../../../../components/seo'
import { HeadingWrapper } from '../../../../components/HeadingWrapper/HeadingWrapper.styled'
import { Heading } from '../../../../components/Heading/Heading.styled'
import { SubHeading } from '../../../../components/SubHeading/SubHeading.styled'
import LinkButton from '../../../../components/LinkButton/LinkButton'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useGetUserTeamQuery } from '../../../../generated/api'
import { IPageProps } from '../../../../contexts/PageContext'

const CreateTeamSuccess: FC<IPageProps<Queries.CreateTeamSuccessQuery>> = () => {
  const { masterUser } = useAuth()
  const { t } = useTranslation()

  const { data: teamRes } = useGetUserTeamQuery(
    {
      input: { id: masterUser?.info.teamId || '', gameId: masterUser?.info.gameId || '' },
    },
    { enabled: Boolean(masterUser?.info.teamId && masterUser.info.gameId) },
  )

  const teamName = teamRes?.getTeam.name

  return (
    <>
      <SEO title={t('createTeam.successPage.title')} />
      <HeadingWrapper>
        <Heading>
          {t('createTeam.successPage.title')}{' '}
          <span role={'img'} aria-label={t('aria.label.raisedHandEmoji') || ''}>
            🙌
          </span>
        </Heading>
        <SubHeading as="h5" secondaryForm veryTall>
          {t('createTeam.successPage.subTitle', { teamName: teamName })}
        </SubHeading>
      </HeadingWrapper>
      <Wrapper width="400px" textAlign="center">
        <Heading style={{ fontSize: '2rem' }}>{t('createTeam.successPage.whatNext')}</Heading>
        <p>{t('createTeam.successPage.approveInfo')}</p>
        <p>{t('createTeam.successPage.waitingTimeInfo')}</p>
      </Wrapper>
      <Wrapper>
        <div style={{ marginTop: 50, marginBottom: 50 }}>
          <LinkButton to="/" secondary replace>
            {t('common.continue') || ''}
          </LinkButton>
        </div>
      </Wrapper>
    </>
  )
}

export const query = graphql`
  query CreateTeamSuccess($language: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      ...FLocales
    }
  }
`

export default CreateTeamSuccess
