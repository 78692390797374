import React, { useEffect, useState } from 'react'
import Wrapper from '../../../../components/Wrapper/Wrapper'
import useAuth from '../../../../contexts/AuthContext/useAuth'
import theme from '../../../../styles/theme'
import Button from '../../../../components/Button/Button'
import useNavigate from '../../../../hooks/useNavigate'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import useGame from '../../../../contexts/GameContext/useGame'
import { useGetUserTeamQuery } from '../../../../generated/api'

const JoinTeamSuccess = ({ setStep }) => {
  const { user, masterUser, family, setUser, refetch } = useAuth()
  const { activeGame: game } = useGame()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const { data: teamRes } = useGetUserTeamQuery(
    {
      input: { id: masterUser?.info.teamId || '', gameId: masterUser?.info.gameId || '' },
    },
    { enabled: Boolean(masterUser?.info.teamId && masterUser.info.gameId) },
  )

  const teamName = teamRes?.getTeam.name

  const isParent = masterUser?.id === family?.[0]?.role.parentId

  const filterFamilyMember = family?.find(
    (member) =>
      !member.tags?.length &&
      (member.role.isChild === true || member?.id === family?.[0]?.role.parentId) &&
      user?.id !== member.id,
  )

  const onNext = async () => {
    setLoading(true)
    if (filterFamilyMember && isParent) {
      setStep(1)
      await setUser(filterFamilyMember.id)
      return setLoading(false)
    }
    await navigate('/')
    if (masterUser?.id) {
      await setUser(masterUser?.id)
      return setLoading(false)
    }
    setLoading(false)
  }

  return (
    <Wrapper style={{ marginTop: 50 }}>
      <p style={{ color: theme.colors.body }}>
        {t('gameSetup.successPage.text', { firstName: user?.profile.firstName })}
      </p>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', color: theme.colors.body }}>
        <p style={{ fontWeight: 'bold', marginRight: 10 }}>{t('gameSetup.successPage.game')}</p>
        <p>{game?.info.name}</p>
      </div>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', color: theme.colors.body }}>
        <p style={{ fontWeight: 'bold', marginRight: 10 }}>{t('gameSetup.successPage.team')}</p>
        <p>
          {user?.info.teamId !== t('gameSetup.successPage.noTeam') && user?.info.teamId !== null
            ? teamName
            : t('gameSetup.successPage.noTeam')}
        </p>
      </div>
      <Button
        onClick={onNext}
        secondary
        isSubmitting={loading}
        disabled={loading}
        text={filterFamilyMember && isParent ? t('gameSetup.successPage.buttonNextFamilyMember') : t('common.finish')}
        style={{ marginTop: 50 }}
      />
    </Wrapper>
  )
}

export default JoinTeamSuccess
